const CatchphraseSection = () => (
  <div className="section-block pb-0 bkg-grey-ultralight" style={{ zIndex: "1" }}>
    <div className="row">
      <div className="column width-8 offset-2 center">
        <h2>
          게시판이나 채팅이 아닌
          <br />
          메타버스에서 가상자산에 대해 이야기하세요
        </h2>
      </div>
    </div>
  </div>
);

export default CatchphraseSection;
