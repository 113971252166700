const handleRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{3,16}$/;
const allowedHandleSpecialChars = /^[\w.]*$/;
const consecutiveDotsRegex = /(\.\.)/;
const startOrEndWithDotRegex = /(^\.|\.$)/;
const koreanCharsRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!#$%^*+=-])(?=.*[0-9]).{8,24}$/;
const allowedPasswordSpecialChars = /^[a-zA-Z0-9!#$%^*+=-]*$/;
const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

export {
  handleRegex,
  allowedHandleSpecialChars,
  consecutiveDotsRegex,
  startOrEndWithDotRegex,
  koreanCharsRegex,
  passwordRegex,
  allowedPasswordSpecialChars,
  emailRegex,
};
