import { useLocation } from "react-router-dom";
import FooterLogo from "../Footer/FooterLogo";
import FooterCompany from "../Footer/FooterCompany";
import FooterPolicy from "../Footer/FooterPolicy";
import FooterSocial from "../Footer/FooterSocial";
import FooterCopyright from "../Footer/FooterCopyright";
import FooterSupport from "../Footer/FooterSupport";

const Footer = () => {
  const location = useLocation();
  const isMainPage = location.pathname === "/";

  const footerStyle = isMainPage ? { zIndex: 1 } : {};

  return (
    <footer className="footer" style={footerStyle}>
      <div className="footer-top">
        <div className="row flex">
          <div className="column width-3">
            <FooterLogo />
          </div>
          <div className="column width-9">
            <div className="row two-columns-on-tablet">
              <div className="column width-3">
                <FooterCompany />
              </div>
              <div className="column width-3">
                <FooterPolicy />
              </div>
              <div className="column width-3">
                <FooterSupport />
              </div>
              <div className="column width-3">
                <FooterSocial />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterCopyright />
    </footer>
  );
};

export default Footer;
