import React, { useState, useRef, useEffect } from "react";
import ContentImage1 from "../../images/shop/content-image-1.jpg";
import products from "../../utils/marketData";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../constants/colors";
import { TbMoodEmpty } from "react-icons/tb";

const MarketMain = () => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [page, setPage] = useState(1);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const itemsPerPage = 9;
  const filteredProducts = categoryFilter === "all" ? products : products.filter((product) => product.category === categoryFilter);
  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);
  const displayedItems = filteredProducts.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const dropdownRef = useRef(null);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLinkClick = (e, newPage) => {
    e.preventDefault();
    if (newPage < 1 || newPage > pageCount) {
      return;
    }
    handlePageChange(newPage);
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownActive(!isDropdownActive);
  };

  const handleFilterChange = (e, filter) => {
    e.preventDefault();
    setCategoryFilter(filter);
    setPage(1);
    setIsDropdownActive(false);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const startIndex = (page - 1) * itemsPerPage + 1;
  const endIndex = Math.min(page * itemsPerPage, filteredProducts.length);

  const getCategoryLabel = () => {
    switch (categoryFilter) {
      case "cameras":
        return "Cameras";
      case "headphones":
        return "Headphones";
      case "keyboards":
        return "Keyboards";
      case "phones":
        return "Phones";
      case "shoes":
        return "Shoes";
      case "speakers":
        return "Speakers";
      default:
        return "모두";
    }
  };

  return (
    <>
      <div className="section-block feature-1 overlap-bottom pb-0 bkg-ash">
        <div className="row">
          <div className="column width-12">
            <div className="feature-image">
              <div className="thumbnail background-image-container large background-cover rounded shadow">
                <img src={ContentImage1} alt="장터 페이지 대표 이미지" style={{ width: "100%", height: "400px" }} />
                <div className="caption-over-outer">
                  <div className="caption-over-inner center color-white">
                    <div className="row">
                      <div className="column width-8 offset-2">
                        <h1>Unique, One of a Kind Products</h1>
                      </div>
                      <div className="column width-6 offset-3">
                        <p className="lead">Whether you're looking for connected sneakers or the latest gear, we've got you covered.</p>
                        <Link
                          to="#product-grid"
                          data-offset="-140"
                          className="scroll-link button rounded medium no-margins mb-0 hover-717dfb"
                          style={buttonStyle}
                        >
                          Start Shopping
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-block pt-0 pb-0 grid-filter-dropdown" data-target-grid="#product-grid">
        <div className="freeze pt-10 bkg-white" data-extra-space-top="60" data-extra-space-bottom="0">
          <div className="row">
            <div className="column width-12">
              <div className="filter-menu-inner">
                <div className="row flex">
                  <div className="column width-8 v-align-middle">
                    <div className="product-result-count">
                      <p className="mb-0 mb-mobile-30">
                        Showing {startIndex}–{endIndex} of {filteredProducts.length} results
                      </p>
                    </div>
                  </div>
                  <div className="column width-4 right left-on-mobile">
                    <span className="dropdown-label mb-0 mb-mobile-30">카테고리: </span>
                    <div ref={dropdownRef} className="dropdown medium inline-block" data-update-selection>
                      <Link
                        to="#"
                        className={`button rounded center border-grey-light color-grey bkg-hover-theme color-hover-white ${isDropdownActive ? "active" : ""}`}
                        onClick={toggleDropdown}
                      >
                        <span>{getCategoryLabel()}</span>
                        <span className="icon-down-open-mini pull-right"></span>
                      </Link>
                      <ul className={`dropdown-list ${isDropdownActive ? "active" : ""}`}>
                        <li>
                          <Link className={categoryFilter === "all" ? "active" : ""} to="#" onClick={(e) => handleFilterChange(e, "all")}>
                            모두
                          </Link>
                        </li>
                        <li>
                          <Link className={categoryFilter === "cameras" ? "active" : ""} to="#" onClick={(e) => handleFilterChange(e, "cameras")}>
                            Cameras
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={categoryFilter === "headphones" ? "active" : ""}
                            to="#"
                            onClick={(e) => handleFilterChange(e, "headphones")}
                          >
                            Headphones
                          </Link>
                        </li>
                        <li>
                          <Link className={categoryFilter === "keyboards" ? "active" : ""} to="#" onClick={(e) => handleFilterChange(e, "keyboards")}>
                            Keyboards
                          </Link>
                        </li>
                        <li>
                          <Link className={categoryFilter === "phones" ? "active" : ""} to="#" onClick={(e) => handleFilterChange(e, "phones")}>
                            Phones
                          </Link>
                        </li>
                        <li>
                          <Link className={categoryFilter === "shoes" ? "active" : ""} to="#" onClick={(e) => handleFilterChange(e, "shoes")}>
                            Shoes
                          </Link>
                        </li>
                        <li>
                          <Link className={categoryFilter === "speakers" ? "active" : ""} to="#" onClick={(e) => handleFilterChange(e, "speakers")}>
                            Speakers
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="product-grid"
        className="section-block grid-container products fade-in-progressively pt-30"
        data-layout-mode="masonry"
        data-grid-ratio="1.5"
        data-animate-resize
        data-animate-resize-duration="0"
      >
        <div className="row">
          <div className="column width-12">
            <div className="row grid content-grid-3" style={{ position: "relative", height: "fit-content", visibility: "visible" }}>
              {displayedItems.length === 0 ? (
                <div style={{ width: "250px", margin: "0 auto", textAlign: "center" }}>
                  <TbMoodEmpty size={100} style={{ marginTop: "50%" }} />
                  <p style={{ fontSize: "30px" }}>상품 준비중입니다.</p>
                </div>
              ) : (
                displayedItems.map((product) => (
                  <div key={product.id} className={`grid-item product ${product.category}`}>
                    <div
                      className="thumbnail rounded product-thumbnail border-grey-light img-scale-in"
                      data-hover-easing="easeInOut"
                      data-hover-speed="700"
                      data-hover-bkg-color="#ffffff"
                      data-hover-bkg-opacity="0.9"
                    >
                      {product.onSale && (
                        <span className="onsale" style={buttonStyle}>
                          Sale
                        </span>
                      )}
                      <Link className="overlay-link" to={`/marketplace?category=${product.category}&onSale=${product.onSale}`}>
                        <img src={product.image} alt={product.title} />
                        <span className="overlay-info">
                          <span>
                            <span>View</span>
                          </span>
                        </span>
                      </Link>
                      <div className="product-actions center">
                        <Link to="#" className="button rounded small hover-717dfb" style={buttonStyle}>
                          Add To Cart
                        </Link>
                      </div>
                    </div>
                    <div className="product-details center">
                      <h3 className="product-title text-truncate">
                        <Link to="#">{product.title}</Link>
                      </h3>
                      <span className="product-price price">
                        {product.price.old && (
                          <del>
                            <span className="amount">{product.price.old}</span>
                          </del>
                        )}
                        <ins>
                          <span className="amount">{product.price.current}</span>
                        </ins>
                      </span>
                      <div className="product-actions-mobile">
                        <Link to="#" className="button add-to-cart-button rounded small">
                          Add To Cart
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="section-block pagination-3">
            <div className="row">
              <div className="column width-15 market-pagination">
                <ul>
                  <li>
                    <Link
                      className={`pagination-previous ${page === 1 ? "disabled" : ""}`}
                      to="#"
                      onClick={(e) => handleLinkClick(e, page - 1)}
                      disabled={page === 1}
                    >
                      <span className="icon-left-open-mini"></span>
                    </Link>
                  </li>
                  {Array.from({ length: pageCount }, (_, i) => (
                    <li key={i + 1}>
                      <Link className={page === i + 1 ? "current" : undefined} to="#" onClick={(e) => handleLinkClick(e, i + 1)}>
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link
                      className={`pagination-next ${page === pageCount ? "disabled" : ""}`}
                      to="#"
                      onClick={(e) => handleLinkClick(e, page + 1)}
                      disabled={page === pageCount}
                    >
                      <span className="icon-right-open-mini"></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketMain;
