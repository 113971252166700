export const colors = {
  primary: "#2fca74",
  secondary: "#6773f1",
  white: "#ffffff",
};

export const iconStyle = {
  color: colors.primary,
};

export const buttonStyle = {
  backgroundColor: colors.secondary,
  borderColor: colors.secondary,
  color: colors.white,
};

export const outlineButtonStyle = {
  borderColor: colors.secondary,
  backgroundColor: colors.white,
  color: colors.secondary,
};
