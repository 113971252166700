import React, { useState } from "react";
import CartItem from "../../components/Cart/CartItem";
import ThumbSmall1 from "../../images/shop/cart/cart-thumb-small.jpg";
import ThumbSmall2 from "../../images/shop/cart/cart-thumb-small-2.jpg";
import ThumbSmall3 from "../../images/shop/cart/cart-thumb-small-3.jpg";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../constants/colors";
import { CiShoppingCart } from "react-icons/ci";

const Cart = () => {
  const [cartItems, setCartItems] = useState([
    {
      image: ThumbSmall1,
      title: "Bo BeoPlay A1 Grey",
      description: "Military Green",
      price: "3,000",
      quantity: 1,
    },
    {
      image: ThumbSmall2,
      title: "Camera Canon Eos Rebel",
      description: "Space Grey",
      price: "2,000",
      quantity: 1,
    },
    {
      image: ThumbSmall3,
      title: "Apple AirPods",
      description: "White",
      price: "100",
      quantity: 1,
    },
  ]);

  const handleQuantityChange = (index, newQuantity) => {
    setCartItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, quantity: newQuantity } : item)));
  };

  const handleRemoveItem = (index) => {
    setCartItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const totalQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const totalPrice = cartItems
    .reduce((acc, item) => {
      const price = parseFloat(item.price.replace(/,/g, ""));
      return acc + price * item.quantity;
    }, 0)
    .toLocaleString("ko-KR");
  return (
    <>
      <div className="section-block intro-title-1 small">
        <div className="row">
          <div className="column width-10 offset-1">
            <div className="title-container">
              <div className="title-container-inner">
                <div className="row flex">
                  <div className="column width-6 v-align-middle">
                    <div>
                      <h1 className="mb-0 font-400">장바구니</h1>
                      <p className="lead mb-0 mb-mobile-20 font-size-tablet">장바구니에 담기 상품들을 확인해보세요</p>
                    </div>
                  </div>
                  <div className="column width-6 v-align-middle">
                    <div>
                      <ul className="breadcrumb mb-0 pull-right clear-float-on-mobile">
                        <li>
                          <Link to="/">홈</Link>
                        </li>
                        <li>장바구니</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-block pt-0">
        <div className="row">
          <div className="column width-10 offset-1">
            <div className="with-background">
              <div className="row">
                <div className="column width-12">
                  <p className="lead">
                    상품 총 <span className="item-number weight-bold">{totalQuantity}</span> 개의 주문 금액은{" "}
                    <span className="cart-subtotal weight-bold">{totalPrice}</span>
                    원입니다.
                  </p>
                </div>
                <div className="column width-12">
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="column width-12">
                  <div className="cart-overview">
                    <div className="table-responsive">
                      <table className="table non-responsive rounded large cart-table">
                        <thead>
                          <tr>
                            <th className="product-details">상품명</th>
                            <th className="product-quantity">수량</th>
                            <th className="product-subtotal right">가격</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.length === 0 ? (
                            <tr>
                              <td colSpan={3}>
                                <div style={{ margin: "0 auto", textAlign: "center" }}>
                                  <CiShoppingCart size={50} />
                                  <p>장바구니가 비어 있습니다.</p>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            cartItems.map((item, index) => (
                              <CartItem
                                key={index}
                                image={item.image}
                                title={item.title}
                                description={item.description}
                                price={item.price}
                                quantity={item.quantity}
                                onRemove={() => handleRemoveItem(index)}
                                onQuantityChange={(newQuantity) => handleQuantityChange(index, newQuantity)}
                              />
                            ))
                          )}
                          <tr className="cart-actions left-on-mobile">
                            <td colSpan="3">
                              <div className="row flex">
                                <div className="column width-8 hidden">
                                  <div className="cart-coupon-form-container">
                                    <form className="cart-coupon-form merged-form-elements clear-float-on-mobile" action="#" method="post" noValidate>
                                      <div className="row">
                                        <div className="column width-7 mb-mobile-10">
                                          <input type="text" className="form-element rounded medium" placeholder="Coupon Code" tabIndex="4" />
                                        </div>
                                        <div className="column width-5 mb-20">
                                          <input type="submit" value="Apply" className="form-submit button rounded medium" tabIndex="5" />
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="column width-12 right">
                  <div className="row" style={{ marginRight: "0.5rem" }}>
                    <div style={{ marginBottom: "20px" }}>
                      <Link to="#" style={{ color: "#6d7485" }}>
                        <span className="icon-cw"></span> 장바구니 수정
                      </Link>
                    </div>
                    <input
                      type="submit"
                      value="주문하기"
                      style={buttonStyle}
                      className="form-submit button rounded medium hover-717dfb no-margins"
                      tabIndex="6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
