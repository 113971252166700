import { createAction } from "redux-actions";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const login = createAction(LOGIN_USER, (username, password) => {
  return {
    username,
    password,
  };
});

export const register = createAction(REGISTER_USER, (username, email, password) => {
  return {
    username,
    email,
    password,
  };
});

export const loginUserSuccess = (token) => ({
  type: LOGIN_USER_SUCCESS,
  payload: token,
});

export const logout = createAction(LOGOUT_USER);

export const refreshTokenRequest = createAction(REFRESH_TOKEN_REQUEST);
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS, (accessToken) => ({ accessToken }));
export const refreshTokenFailure = createAction(REFRESH_TOKEN_FAILURE, (error) => ({ error }));

export const logoutUser = createAction(LOGOUT_USER);
export const logoutUserSuccess = createAction(LOGOUT_USER_SUCCESS);
export const logoutUserFailure = createAction(LOGOUT_USER_FAILURE, (error) => ({ error }));
