import React, { useState, useEffect } from "react";
import MypageInput from "../MypageInput";
import { passwordRegex, koreanCharsRegex, allowedPasswordSpecialChars } from "../../../constants/Regex";
import { authInstance } from "../../../store/api/baseInstance";
import { useNavigate } from "react-router-dom";
import { selectUserIdFromToken } from "../../../selectors/authSelectors";
import { useSelector } from "react-redux";
import { buttonStyle } from "../../../constants/colors";

const ResetPasswordContent = () => {
  const navigate = useNavigate();
  const userId = useSelector(selectUserIdFromToken);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [confirmError, setConfirmError] = useState(null);

  useEffect(() => {
    if (newPassword.length > 0) {
      if (koreanCharsRegex.test(newPassword)) {
        setPasswordError("조합 가능한 문자는 영문자, 숫자, 특수문자입니다.");
      } else if (!allowedPasswordSpecialChars.test(newPassword)) {
        setPasswordError("조합 가능한 특수문자는 ( !, #, $, %, ^, *, =, - )입니다.");
      } else if (!passwordRegex.test(newPassword)) {
        setPasswordError("영문자, 숫자 및 특수문자를 모두 한 번씩 사용해야 합니다.");
      } else {
        setPasswordError(null);
      }
    }
  }, [newPassword]);

  useEffect(() => {
    if (confirmPassword.length > 0) {
      if (confirmPassword !== newPassword) {
        setConfirmError("비밀번호가 일치하지 않습니다.");
      } else {
        setConfirmError(null);
      }
    }
  }, [confirmPassword, newPassword]);

  const handleChangePassword = async () => {
    const requestBody = {
      oldPassword: currentPassword,
      newPassword: newPassword,
      newPasswordConfirm: confirmPassword,
    };
    try {
      const response = await authInstance.put(`/members/${userId}/password`, requestBody);
      if (response.status === 204) {
        alert("비밀번호가 변경되었습니다.");
        navigate("/mypage/info");
      }
    } catch (error) {
      alert(error.response ? error.response.data.message : error.message);
    }
  };

  return (
    <div class="section-block signup-1 bkg-grey-ultralight">
      <div class="row">
        <div class="column width-8 offset-2 center">
          <div class="signup-form-container">
            <form class="signup-form" action="php/subscribe.php" method="post" novalidate>
              <div class="row">
                <div class="column width-8 offset-2">
                  <div class="field-wrapper">
                    <MypageInput
                      label="현재 비밀번호:"
                      type="password"
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      className="password"
                      placeholder="••••••••••"
                      maxLength={24}
                    />
                    <MypageInput
                      label="새 비밀번호:"
                      type="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="password"
                      placeholder="••••••••••"
                      maxLength={24}
                    />
                    {passwordError && <p className="mypageInputErrorMsg left">{passwordError}</p>}
                    <MypageInput
                      label="새 비밀번호 확인:"
                      type="password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="password"
                      placeholder="••••••••••"
                      maxLength={24}
                    />
                    {confirmError && <p className="mypageInputErrorMsg left">{confirmError}</p>}
                  </div>
                </div>
                <div class="column width-4 offset-4">
                  <input
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangePassword();
                    }}
                    value="비밀번호 변경"
                    class="form-submit button rounded medium hover-717dfb"
                    style={buttonStyle}
                  />
                </div>
              </div>
              <input type="text" name="honeypot" class="form-honeypot form-element rounded medium" />
            </form>
            <div class="form-response show full-width center"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordContent;
