import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import { rootSaga } from "./reducers";
import {isProduction} from "../utils/isProduction";

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware) => {
  if (!isProduction) {
    return getDefaultMiddleware({ thunk: false }).concat(logger, sagaMiddleware);
  }
  return getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: !isProduction,
});

sagaMiddleware.run(rootSaga);
export default store;
