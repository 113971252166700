import { useState } from "react";
import { Link, useResolvedPath } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { sitemap } from "../Layout/Sitemap";

const SidebarMenuItem = ({ children, name, to, ...props }) => {
  const breadcrumbs = useReactRouterBreadcrumbs(sitemap);
  const resolved = useResolvedPath(to);
  const match = breadcrumbs.map((breadcrumb) => breadcrumb.match.pathname).includes(resolved.pathname);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <li className={match ? "current" : undefined}>
      <Link to={to} className={children ? "contains-sub-menu" : undefined} onClick={children ? handleToggle : props.onClick}>
        {name}
      </Link>
      {children && isOpen && <ul>{children}</ul>}
    </li>
  );
};

export default SidebarMenuItem;
