import React from "react";
import { buttonStyle } from "../../constants/colors";

const SubmitButton = ({ value, onClick }) => {
  return (
    <div className="column width-12 center">
      <input type="submit" value={value} onClick={onClick} style={buttonStyle} className="form-submit button rounded medium mb-0 hover-717dfb" />
    </div>
  );
};

export default SubmitButton;
