import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LogoDark from "../../images/logo-dark.png";
import SignInput from "../../components/Sign/SignInput";
import { validatePassword, validateConfirmPassword } from "../../utils/validation";
import { publicInstance } from "../../store/api/baseInstance";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [confirmError, setConfirmError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const email = queryParams.get("email");

  useEffect(() => {
    const error = validatePassword(password);
    setPasswordError(error);
  }, [password]);

  useEffect(() => {
    const error = validateConfirmPassword(passwordConfirm, password);
    setConfirmError(error);
  }, [passwordConfirm, password]);

  const handleNewPassword = async () => {
    if (passwordError || confirmError) {
      return;
    }

    if (password.length === 0) {
      setPasswordError("새 비밀번호를 입력해주세요.");
      return;
    } else if (passwordConfirm.length === 0) {
      setConfirmError("비밀번호를 확인해주세요.");
      return;
    }

    try {
      const body = JSON.stringify({ code, email, password, passwordConfirm });
      const res = await publicInstance.post("/auth/password", body);

      if (res.status === 200) {
        alert("비밀번호가 변경되었습니다.");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      alert(errorMessage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      let hasError = false;

      if (password.length === 0) {
        setPasswordError("새 비밀번호를 입력해주세요.");
        hasError = true;
      } else {
        const error = validatePassword(password);
        if (error) {
          setPasswordError(error);
          hasError = true;
        } else {
          setPasswordError(null);
        }
      }

      if (passwordConfirm.length === 0) {
        setConfirmError("비밀번호를 확인해주세요.");
        hasError = true;
      } else {
        const error = validateConfirmPassword(passwordConfirm, password);
        if (error) {
          setConfirmError(error);
          hasError = true;
        } else {
          setConfirmError(null);
        }
      }

      if (!hasError) {
        handleNewPassword();
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="wrapper-inner">
        <div className="content clearfix">
          <div className="section-block replicable-content window-height bkg-heavy-rain-gradient">
            <div className="row flex v-align-middle">
              <div className="column width-6 offset-3">
                <div>
                  <div className="logo mb-50">
                    <div className="logo-inner center">
                      <Link to="/">
                        <img src={LogoDark} width="125" alt="흑우촌 로고" />
                      </Link>
                    </div>
                  </div>
                  <div className="signup-box box rounded xlarge bkg-white shadow">
                    <p className="mb-20 center">
                      비밀번호가 기억나셨나요?
                      <Link to="/login" className="fade-location">
                        로그인
                      </Link>
                    </p>
                    <div className="login-form-container">
                      <div className="row">
                        <SignInput
                          label="새 비밀번호:"
                          type="password"
                          className="password"
                          placeholder="••••••••"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          value={password}
                        />
                        {passwordError && <p className="signInputErrorMsg">{passwordError}</p>}
                        <SignInput
                          label="새 비밀번호 확인:"
                          type="password"
                          className="password"
                          placeholder="••••••••"
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          value={passwordConfirm}
                        />
                        {confirmError && <p className="signInputErrorMsg">{confirmError}</p>}
                        <div className="column width-12 center">
                          <input
                            type="submit"
                            value="비밀번호 변경"
                            className="form-submit button rounded medium bkg-green bkg-hover-theme bkg-focus-green color-white color-hover-white mb-0"
                            onClick={handleNewPassword}
                          />
                        </div>
                      </div>
                      <div className="form-response show"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
