import {
  handleRegex,
  allowedHandleSpecialChars,
  consecutiveDotsRegex,
  startOrEndWithDotRegex,
  koreanCharsRegex,
  passwordRegex,
  emailRegex,
  allowedPasswordSpecialChars,
} from "../constants/Regex";

export const validateHandle = (handle) => {
  if (handle.length > 0) {
    if (koreanCharsRegex.test(handle)) {
      return "조합 가능한 문자는 영문자, 숫자, 특수문자입니다.";
    }
    if (!allowedHandleSpecialChars.test(handle)) {
      return "조합 가능한 특수문자는 점( . )과 언더바( _ )입니다.";
    }
    if (consecutiveDotsRegex.test(handle)) {
      return "두 개 이상의 점( . )을 연속하여 사용할 수 없습니다.";
    }
    if (startOrEndWithDotRegex.test(handle)) {
      return "점( . )으로 핸들을 시작하거나 끝마칠 수 없습니다.";
    }
    if (!handleRegex.test(handle)) {
      return "핸들 형식이 올바르지 않습니다.";
    }
    return null;
  }
};

export const validatePassword = (password) => {
  if (password.length > 0) {
    if (koreanCharsRegex.test(password)) {
      return "조합 가능한 문자는 영문자, 숫자, 특수문자입니다.";
    } else if (!allowedPasswordSpecialChars.test(password)) {
      return "조합 가능한 특수문자는 ( !, #, $, %, ^, *, =, - )입니다.";
    } else if (!passwordRegex.test(password)) {
      return "영문자, 숫자 및 특수문자를 모두 한 번씩 사용해야 합니다.";
    } else {
      return null;
    }
  }
};

export const validateRegisterHandle = (handle) => {
  if (handle.length > 0) {
    if (koreanCharsRegex.test(handle)) {
      return "조합 가능한 문자는 영문자, 숫자, 특수문자입니다.";
    } else if (!allowedHandleSpecialChars.test(handle)) {
      return "조합 가능한 특수문자는 점( . )과 언더바( _ )입니다.";
    } else if (consecutiveDotsRegex.test(handle)) {
      return "두 개 이상의 점( . )을 연속하여 사용할 수 없습니다.";
    } else if (startOrEndWithDotRegex.test(handle)) {
      return "점( . )으로 핸들을 시작하거나 끝마칠 수 없습니다.";
    } else if (!handleRegex.test(handle)) {
      return "핸들 형식이 올바르지 않습니다.";
    } else {
      return null;
    }
  } else {
    return "핸들은 변경이 불가하니 신중하게 작성해주세요.";
  }
};

export const validateEmail = (email) => {
  if (email.length > 0) {
    if (!emailRegex.test(email)) {
      return "이메일 형식이 올바르지 않습니다.";
    } else {
      return null;
    }
  }
};

export const validateConfirmPassword = (passwordConfirm, password) => {
  if (passwordConfirm.length > 0 && passwordConfirm !== password) {
    return "비밀번호가 일치하지 않습니다.";
  } else {
    return null;
  }
};
