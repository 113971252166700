import React from "react";

const TextAvatar = ({ username, style }) => {
  const firstLetter = username ? username.charAt(0).toUpperCase() : "";

  return (
    <div className="avatar" style={style}>
      <span className="avatar-text">{firstLetter}</span>
    </div>
  );
};

export default TextAvatar;
