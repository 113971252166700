import React from 'react';
import BreadCrumbs from '../TitleContainer/BreadCrumbs';
import classNames from "classnames";

const TitleContainer = ({title, description, style}) => (
    <div className={classNames("section-block intro-title-1 small", style)}>
        <div className="row">
            <div className="column width-12">
                <div className="title-container">
                    <div className="title-container-inner">
                        <div className="row flex">
                            <div className="column width-6 v-align-middle">
                                <div>
                                    <h1 className="mb-0">{title}</h1>
                                    <p className="lead mb-0 mb-mobile-20">{description}</p>
                                </div>
                            </div>
                            <div className="column width-6 v-align-middle">
                                <BreadCrumbs />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default TitleContainer;
