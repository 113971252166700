import React, { useState, useEffect } from "react";
import PassInfo from "./PassInfo";
import ItemTable from "../../common/ItemTable";
import PassModalImg from "../../../images/PassModal.png";
import { Link } from "react-router-dom";
import { authInstance } from "../../../store/api/baseInstance";
import { buttonStyle, outlineButtonStyle } from "../../../constants/colors";

const PaymentContent = ({ subscribeId }) => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isRestarted, setIsRestarted] = useState(true);

  useEffect(() => {
    const fetchOrderData = async () => {
      if (subscribeId) {
        try {
          const response = await authInstance.get(`/pass/${subscribeId}`);
          const data = response.data;

          const formatDate = (dateTime) => dateTime.split("T")[0];

          const orders = data.relatedOrders.length > 0 ? data.relatedOrders : [data.parentOrder];

          const formattedItems = orders.map((order) => {
            const paymentMethod = data.parentOrder.payment.paymentMethod === "CARD_BILL" ? "신용카드" : data.parentOrder.payment.paymentMethod;
            const passStatus = order.status === "PAID" ? "결제 완료" : order.status;
            return {
              paymentDate: formatDate(order.paymentDate || data.parentOrder.payment.paymentDate),
              description: order.planName || data.parentOrder.planName,
              paymentMethod: paymentMethod,
              paidAmount: `${order.paidAmount || data.parentOrder.paidAmount}원`,
              status: passStatus,
            };
          });

          setItems(formattedItems);
        } catch (error) {
          console.error("결제 내역을 불러오는데 실패하였습니다: ", error);
        }
      }
    };

    fetchOrderData();
  }, [subscribeId]);

  const fetchCancelSubscribe = async () => {
    if (subscribeId) {
      try {
        const response = await authInstance.post(`/pass/${subscribeId}/cancel`);
        if (response.status === 200) {
          alert("구독 해지 신청이 완료되었습니다.");
          setIsRestarted(false);
        }
      } catch (error) {
        console.error("구독을 해지하는데 실패하였습니다: ", error);
      }
    }
  };

  const handleCancelButtonClick = (e) => {
    e.preventDefault();
    fetchCancelSubscribe();
  };

  const theadCategory = [
    { label: "결제일", className: "product-category text-left" },
    { label: "설명", className: "product-number text-left" },
    { label: "결제 수단", className: "product-date text-center" },
    { label: "결제 금액", className: "product-deadline text-center" },
    { label: "결제 상태", className: "product-price-table text-right" },
  ];

  const pageCount = Math.ceil(items.length / 6);
  const displayedItems = items.slice((page - 1) * 6, page * 6);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLinkClick = (event, newPage) => {
    event.preventDefault();
    if (newPage < 1 || newPage > pageCount) {
      return;
    }
    handlePageChange(newPage);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div>
        <PassInfo toggleModal={toggleModal} subscribeId={subscribeId} isRestarted={isRestarted} />
        <ItemTable theadCategory={theadCategory} items={displayedItems} handleLinkClick={handleLinkClick} theadColor="grey" />
      </div>
      <div className="section-block pagination-3">
        <div className="row">
          <div className="column width-15">
            <ul>
              <li>
                <Link
                  className={`pagination-previous ${page === 1 ? "disabled" : ""}`}
                  to="#"
                  onClick={(e) => handleLinkClick(e, page - 1)}
                  aria-disabled={page === 1}
                >
                  <span className="icon-left-open-mini"></span>
                </Link>
              </li>
              {Array.from({ length: pageCount }, (_, i) => (
                <li key={i + 1}>
                  <Link className={page === i + 1 ? "current" : undefined} to="#" onClick={(e) => handleLinkClick(e, i + 1)}>
                    {i + 1}
                  </Link>
                </li>
              ))}
              <li>
                <Link
                  className={`pagination-next ${page === pageCount ? "disabled" : ""}`}
                  to="#"
                  onClick={(e) => handleLinkClick(e, page + 1)}
                  aria-disabled={page === pageCount}
                >
                  <span className="icon-right-open-mini"></span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showModal && (
        <div
          id="tm-lightbox"
          className="tm-lightbox tml-promotion-modal tml-padding-small tml-swap-exit-light height-auto rounded tml-modal-mode"
          style={{
            opacity: "1",
            transition: "-webkit-transform 350ms cubic-bezier(0.42, 0, 0.58, 1) 0s",
            transform: "translated3d(0px, 0px, 0px)",
            visibility: "visible",
            background: "rgba(0, 0, 0, 0.2)",
          }}
          onClick={toggleModal}
        >
          <div id="tml-content-wrapper" style={{ inset: "15%" }}>
            <div
              id="tml-content"
              className="modal-dialog scale-in scrollable-content"
              style={{
                transition: "-webkit-transform 250ms cubic-bezier(0.42, 0, 0.58, 1) 0s",
                opacity: "1",
                visibility: "visible",
                top: "13rem",
              }}
            >
              <div id="promotion-modal" className="hide show" style={{ visibility: "visible", opacity: "1" }}>
                <div className="section-block hero-5 pb-mobile-20 show-media-column-on-mobile">
                  <div className="media-column width-5">
                    <img src={PassModalImg} alt="modal" className="media-overlay bkg-black" />
                  </div>
                  <div className="row">
                    <div className="column width-5 offset-6">
                      <div className="hero-content split-hero-content tmh-perspective">
                        <div
                          className="hero-content-inner left"
                          data-animate-in="preset:slideInRightShort;duration:1000ms;delay:200ms;"
                          data-threshold="0.6"
                        >
                          <h3 className="mb-30">정말 해지하시겠어요?</h3>
                          <p>
                            Morbi nec ultrices tellus. Fusce id est quis orci faucibus congue. Aliquam erat volutpat. Phasellus tortor velit, ornare
                            at ullamcorper at, ultrices et justo. Cum sociis natoque penatibus et magnis.
                          </p>
                          <div className="product-actions">
                            <Link to="#" style={buttonStyle} className="button rounded medium hover-717dfb medium">
                              계속 이용하기
                            </Link>
                            <Link
                              to="#"
                              onClick={handleCancelButtonClick}
                              style={outlineButtonStyle}
                              className="button rounded medium hover-717dfb-color-white"
                            >
                              해지하기
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="#" id="tml-exit" className="tml-nav cursor-pointer" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentContent;
