import React from "react";

const SignInput = ({ label, type, value, onChange, className, placeholder, maxLength, onKeyDown }) => {
  return (
    <div className="column width-12">
      <div className="field-wrapper">
        <label className="color-charcoal">{label}</label>
        <input
          type={type}
          value={value}
          onChange={onChange}
          className={`form-${className} form-element rounded medium`}
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          required
        />
      </div>
    </div>
  );
};

export default SignInput;
