import React from 'react';

const Section = ({ title, lead, description }) => (
    <div className="column width-4 v-align-middle">
        <div>
            <h3 className="weight-semi-bold mb-20">{title}</h3>
            <p className="lead">{lead}</p>
            <p>{description}</p>
        </div>
    </div>
);

export default Section;
