import React, { useState, useEffect } from "react";
import SingleProduct1 from "../../images/shop/single/product-1.jpg";
import SingleProduct2 from "../../images/shop/single/product-2.jpg";
import SingleProduct3 from "../../images/shop/single/product-3.jpg";
import LargeMargin1 from "../../images/shop/grid/large-margins/product-1.jpg";
import LargeMargin2 from "../../images/shop/grid/large-margins/product-5.jpg";
import LargeMargin3 from "../../images/shop/grid/large-margins/product-6.jpg";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../constants/colors";

const ProductPage = () => {
  const [quantity, setQuantity] = useState("");
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [strap, setStrap] = useState("");
  const location = useLocation();
  const { category, onSale } = queryString.parse(location.search);

  const handleAddToCart = (e) => {
    e.preventDefault();
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [SingleProduct1, SingleProduct2, SingleProduct3];

  const handlePrevClick = (e) => {
    e.preventDefault();
    setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    document.body.classList.add("single-product");
    document.body.classList.add("transition-support");
    document.body.classList.add("webkit");
    document.body.classList.remove("home-page");

    return () => {
      document.body.classList.remove("single-product");
      document.body.classList.remove("transition-support");
      document.body.classList.remove("webkit");
      document.body.classList.add("home-page");
    };
  }, []);

  return (
    <>
      <div className="section-block intro-title-1 small">
        <div className="row">
          <div className="column width-12">
            <div className="title-container">
              <div className="title-container-inner">
                <div className="row flex">
                  <div className="column width-6 v-align-middle">
                    <div>
                      <h1 className="mb-0">B&amp;o BeoPlay A1</h1>
                      <p className="lead mb-0 mb-mobile-20">Bluetooth play n'go</p>
                    </div>
                  </div>
                  <div className="column width-6 v-align-middle">
                    <div>
                      <ul className="breadcrumb mb-0 pull-right clear-float-on-mobile">
                        <li>
                          <Link to="/">홈</Link>
                        </li>
                        <li>
                          <Link to="/marketplace">장터</Link>
                        </li>
                        <li>{category}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-block clearfix pt-0">
        <div className="row">
          <div className="column width-8 content-inner">
            <div className="row">
              <div className="column width-12">
                <div className="freeze-wrapper" style={{ height: "541px" }}>
                  <div
                    className="product freeze out-of-view"
                    data-extra-space-top="100"
                    data-extra-space-bottom="90"
                    data-push-section=".products-similar"
                    data-width="730"
                    data-height="541"
                    style={{ width: "100%", display: "flex", flexDirection: "column" }}
                  >
                    <div className="product-images sticky">
                      <div
                        className="tm-slider-container content-slider mb-0 tms-nav-dark lazyload"
                        data-animation="slide"
                        data-scale-min-height="100"
                        data-scale-under="1080"
                        data-width="1080"
                        data-height="800"
                        data-nav-show-on-hover="false"
                        style={{ width: "100%", height: "541px" }}
                      >
                        <ul className="tms-slides">
                          {images.map((image, index) => (
                            <li
                              key={index}
                              className={`tms-slide tms-forcefit ${index === activeIndex ? "active" : ""} img-loaded`}
                              data-image
                              data-force-fit
                              data-nav-dark
                              data-as-bkg-image
                              style={{ opacity: "1", visibility: "visible", zIndex: index === activeIndex ? "2" : "1" }}
                            >
                              <img
                                src={image}
                                data-retina
                                alt="상품 대표 이미지"
                                className="product-detail-main-img"
                                style={{ opacity: "1", width: "100%", height: "541px", top: "0", left: "0" }}
                              />
                            </li>
                          ))}
                        </ul>
                        <Link to="#" id="tms-prev" className="tms-arrow-nav" style={{ display: "block" }} onClick={handlePrevClick}></Link>
                        <Link to="#" id="tms-next" className="tms-arrow-nav" style={{ display: "block" }} onClick={handleNextClick}></Link>
                        <div className="tms-pagination" style={{ display: "block" }}>
                          {images.map((_, index) => (
                            <Link
                              to="#"
                              key={index}
                              id={`tms-pagination-${index + 1}`}
                              data-index={index + 1}
                              className={`tms-bullet-nav ${index === activeIndex ? "active" : ""}`}
                            ></Link>
                          ))}
                        </div>
                      </div>
                      <div className="hide">
                        <Link className="lightbox-link" data-group="product-lightbox-gallery" to={SingleProduct2}></Link>
                        <Link className="lightbox-link" data-group="product-lightbox-gallery" to={SingleProduct3}></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside className="column width-4 sidebar right">
            <div className="sidebar-inner">
              <div className="product-summary">
                <div className="row">
                  <div className="column width-12">
                    <div className="product-price price">
                      {onSale === "true" && (
                        <del>
                          <span className="amount font-big-amount">$378.00</span>
                        </del>
                      )}
                      <ins>
                        <span className="amount font-big-amount">$278.00</span>
                      </ins>
                    </div>
                    <div className="product-description">
                      <hr className="mt-0" />
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque
                        ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                      <Link to="#">Read Full Description &rarr;</Link>
                    </div>
                    <div className="product-specs mb-10">
                      <hr />
                      <h4>Device Color</h4>
                      <form className="product-form" onSubmit={handleAddToCart}>
                        <div className="row">
                          <div className="column width-12">
                            <div className="form-select form-element rounded medium mb-20">
                              <select name="product-color" value={color} onChange={(e) => setColor(e.target.value)}>
                                <option value="">Select..</option>
                                <option value="Gold">Gold</option>
                                <option value="Grey">Grey</option>
                                <option value="Green">Green</option>
                                <option value="Purple">Purple</option>
                                <option value="Rust">Rust</option>
                                <option value="White">White</option>
                              </select>
                            </div>
                            <h4>Device Size</h4>
                            <div className="form-select form-element rounded medium mb-20">
                              <select name="product-size" value={size} onChange={(e) => setSize(e.target.value)}>
                                <option value="">Select..</option>
                                <option value="Mini">Mini</option>
                                <option value="Regular">Regular</option>
                              </select>
                            </div>
                            <h4>Strap Type</h4>
                            <div className="form-select form-element rounded medium mb-20">
                              <select name="product-strap" value={strap} onChange={(e) => setStrap(e.target.value)}>
                                <option value="">Select..</option>
                                <option value="Magnolia Leather">Magnolia Leather</option>
                                <option value="Nylon">Nylon</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row merged-form-elements">
                          <div className="column width-5">
                            <input
                              type="text"
                              name="quantity"
                              className="form-quantity form-element medium"
                              placeholder="Qty"
                              value={quantity}
                              onChange={(e) => setQuantity(e.target.value)}
                              required
                            />
                          </div>
                          <div className="column width-7">
                            <input
                              type="submit"
                              value="Add To Cart"
                              className="form-submit button full-width rounded medium hover-717dfb"
                              style={buttonStyle}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="widget social">
                  <hr className="mt-0" />
                  <ul className="social-list list-horizontal left">
                    <li>
                      <h5>Share:</h5>
                    </li>
                    <li>
                      <Link to="#" className="socialize" data-network="pinterest" title="Share on Pinterest">
                        <span className="icon-pinterest small"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="socialize" data-network="facebook" title="Share on Facebook">
                        <span className="icon-facebook small"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="socialize" data-network="googleplus" title="Share on Google">
                        <span className="icon-google small"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="socialize" data-network="twitter" title="Share on Twitter">
                        <span className="icon-twitter small"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="socialize" data-network="linkedin" title="Share on LinkedIn">
                        <span className="icon-linkedin small"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="socialize" data-network="email" title="Share by Email">
                        <span className="icon-email small"></span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
          <div className="column width-12">
            <div className="products-similar">
              <hr />
              <h3 className="mb-50">Related Products</h3>
              <div
                id="product-grid"
                className="grid-container products fade-in-progressively no-padding-top"
                data-layout-mode="masonry"
                data-grid-ratio="1.5"
                data-animate-resize
                data-animate-resize-duration="0"
              >
                <div className="row">
                  <div className="column width-12">
                    <div className="row grid content-grid-3" style={{ visibility: "visible" }}>
                      <div className="grid-item product grid-sizer cameras">
                        <div
                          className="thumbnail rounded product-thumbnail border-grey-light img-scale-in"
                          data-hover-easing="easeInOut"
                          data-hover-speed="700"
                          data-hover-bkg-color="#ffffff"
                          data-hover-bkg-opacity="0.9"
                        >
                          <span className="onsale">Sale</span>
                          <Link className="overlay-link" to="single-product-device.html">
                            <img src={LargeMargin1} alt="grid1" style={{ transitionDuration: "700ms" }} />
                            <span className="overlay-info" style={{ transitionDuration: "700ms", backgroundColor: "rgba(255, 255, 255, 0.9" }}>
                              <span>
                                <span>View</span>
                              </span>
                            </span>
                          </Link>
                          <div className="product-actions center">
                            <Link to="#" className="button add-to-cart-button rounded small hover-717dfb" style={buttonStyle}>
                              Add To Cart
                            </Link>
                          </div>
                        </div>
                        <div className="product-details center">
                          <h3 className="product-title text-truncate">
                            <Link to="#">Camera Canon Eos Rebel</Link>
                          </h3>
                          <span className="product-price price">
                            <del>
                              <span className="amount">$1695.00</span>
                            </del>
                            <ins>
                              <span className="amount">$1199.00</span>
                            </ins>
                          </span>
                          <div className="product-actions-mobile">
                            <Link to="#" className="button add-to-cart-button rounded small hover-717dfb" style={buttonStyle}>
                              Add To Cart
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="grid-item product headphones">
                        <div
                          className="thumbnail rounded product-thumbnail border-grey-light img-scale-in"
                          data-hover-easing="easeInOut"
                          data-hover-speed="700"
                          data-hover-bkg-color="#ffffff"
                          data-hover-bkg-opacity="0.9"
                        >
                          <Link className="overlay-link" to="single-product-device.html">
                            <img src={LargeMargin2} alt="grid2" style={{ transitionDuration: "700ms" }} />
                            <span className="overlay-info" style={{ transitionDuration: "700ms", backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
                              <span>
                                <span>View</span>
                              </span>
                            </span>
                          </Link>
                          <div className="product-actions center">
                            <Link to="#" className="button add-to-cart-button rounded small hover-717dfb" style={buttonStyle}>
                              Add To Cart
                            </Link>
                          </div>
                        </div>
                        <div className="product-details center">
                          <h3 className="product-title text-truncate">
                            <Link to="#">Apple AirPods</Link>
                          </h3>
                          <span className="product-price price">
                            <ins>
                              <span className="amount">$169.00</span>
                            </ins>
                          </span>
                          <div className="product-actions-mobile">
                            <Link to="#" className="button add-to-cart-button rounded small hover-717dfb" style={buttonStyle}>
                              Add To Cart
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="grid-item product speakers">
                        <div
                          className="thumbnail rounded product-thumbnail border-grey-light img-scale-in"
                          data-hover-easing="easeInOut"
                          data-hover-speed="700"
                          data-hover-bkg-color="#ffffff"
                          data-hover-bkg-opacity="0.9"
                        >
                          <Link className="overlay-link" to="single-product-device.html">
                            <img src={LargeMargin3} alt="grid3" style={{ transitionDuration: "700ms" }} />
                            <span className="overlay-info" style={{ transitionDuration: "700ms", backgroundColor: "rgba(255, 255, 255, 0.9" }}>
                              <span>
                                <span>View</span>
                              </span>
                            </span>
                          </Link>
                          <div className="product-actions center">
                            <Link to="#" className="button add-to-cart-button rounded small hover-717dfb" style={buttonStyle}>
                              Add To Cart
                            </Link>
                          </div>
                        </div>
                        <div className="product-details center">
                          <h3 className="product-title text-truncate">
                            <Link to="#">Bo BeoPlay A1 Grey</Link>
                          </h3>
                          <span className="product-price price">
                            <ins>
                              <span className="amount">$278.00</span>
                            </ins>
                          </span>
                          <div className="product-actions-mobile">
                            <Link to="#" className="button add-to-cart-button rounded small hover-717dfb" style={buttonStyle}>
                              Add To Cart
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
