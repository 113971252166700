import React from "react";
import { Link, useLocation } from "react-router-dom";

const Category = () => {
  const location = useLocation();

  const getLinkClassName = (path) => {
    return location.pathname === path ? "scroll-link text-black" : "scroll-link";
  };

  return (
    <div
      className="section-block pt-0 pb-0 header-sub freeze bkg-white border-grey-light mypage-category"
      data-extra-space-top="0"
      data-extra-space-bottom="100"
    >
      <div className="row flex mt-15">
        <div className="column width-6 v-align-middle left">
          <nav className="navigation nav-block nav-left">
            <ul>
              <li>
                <Link to="/mypage/info" className={getLinkClassName("/mypage/info")}>
                  내 정보
                </Link>
              </li>
              <li>
                <Link to="/mypage/reset_password" className={getLinkClassName("/mypage/reset_password")}>
                  비밀번호 변경
                </Link>
              </li>
              <li>
                <Link to="/mypage/pass" className={getLinkClassName("/mypage/pass")}>
                  흑우촌 패스
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Category;
