import React from "react";
import { Link } from "react-router-dom";

const CartItem = ({ image, title, description, price, quantity, onRemove, onQuantityChange }) => {
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    onQuantityChange(newQuantity);
  };

  return (
    <tr className="cart-item">
      <td className="product-details">
        <span>
          <img src={image} className="rounded" width="60" alt="" />
          <span>
            <Link to="#" className="product-title">
              {title}
            </Link>
            <span className="product-description">
              <em>
                {description} <span className="amount">- ₩{price}</span>
              </em>
            </span>
            <Link to="#" className="product-remove" onClick={onRemove}>
              삭제
            </Link>
          </span>
        </span>
      </td>
      <td className="product-quantity">
        <input
          type="number"
          step="1"
          min="1"
          name="quantity"
          value={quantity}
          title="Qty"
          className="form-element rounded small quantity"
          size="4"
          tabIndex="1"
          onChange={handleQuantityChange}
        />
      </td>
      <td className="product-subtotal right">
        <span className="amount">{price}원</span>
      </td>
    </tr>
  );
};

export default CartItem;
