import React from 'react';
import Accordion from "./Accordion/Accordion";

const questionsAnswers = [
    {
        question: "접속에 필요한 계정은 어디서 만들 수 있나요?",
        answer: "<p>계정은 웹사이트에서 회원가입 절차를 거치면 만들 수 있습니다. 흑우촌 클라이언트에서 회원가입때 작성한 아이디와 비밀번호로 접속하실 수 있습니다.</p>",
    },
    {
        question: "모바일은 지원하지 않나요?",
        answer:
            "<p>안타깝지만 흑우촌 메타버스는 현재 PC환경만 지원합니다. 모바일 환경은 흑우촌 메타버스의 플랫폼 전환시 지원할 예정입니다.</p>",
    },
    {
        question: "최소 및 권장 사양은 어떻게 되나요?",
        answer: `
                <p class="lead mb-20">최소 사양</p>
                <div class="widget">
                    <ul class="list-unstyled">
                        <li class="project-attribute">
                            <span class="project-label">인터넷 연결:</span><span
                            class="project-value">케이블 또는 DSL</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">운영체제:</span><span
                            class="project-value">Windows 8 또는 Mac OS X 10.12</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">프로세서:</span><span
                            class="project-value">SSE2 명령어를 지원하는 Intel Pentium 4, Pentium M, Core, Atom, AMD Athlon 64</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">RAM:</span><span
                            class="project-value">1GB</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">디스플레이:</span><span
                            class="project-value">1024x768 해상도를 지원하는 장치</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">그래픽 카드:</span><span
                            class="project-value">NVIDIA GeForce 6600, ATI Radeon 9500, Intel 945 칩셋</span>
                        </li>
                    </ul>
                </div>
                <hr/>
                <p class="lead mb-20">권장 사양</p>
                <div class="widget">
                    <ul class="list-unstyled">
                        <li class="project-attribute">
                            <span class="project-label">인터넷 연결:</span><span
                            class="project-value">100Mbps 이더넷</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">운영체제:</span><span
                            class="project-value">Windows 10 또는 최신 macOS</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">프로세서:</span><span
                            class="project-value">2개 이상의 코어가 장착된 2GHz 이상의 64비트 프로세서</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">RAM:</span><span
                            class="project-value">4GB</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">디스플레이:</span><span
                            class="project-value">1024x768 해상도를 지원하는 장치 이상</span>
                        </li>
                        <li class="project-attribute">
                            <span class="project-label">그래픽 카드:</span><span
                            class="project-value">NVIDIA GeForce 9600, 9800, ATI Radeon 4850, 4870, 4890</span>
                        </li>
                    </ul>
                </div>
                `,
    },
];

const DownloadFAQ = () => (
    <div className="section-block clearfix pt-0">
        <div className="row">
            <div className="column width-3">
                <h3 className="mb-50">자주 묻는 질문</h3>
            </div>
            <div className="column width-8 offset-1">
                <Accordion questionsAnswers={questionsAnswers} />
            </div>

        </div>
    </div>
);

export default DownloadFAQ;
