import TitleContainer from "../../components/common/Layout/TitleContainer";
import TosContents from "../../components/Tos/TosContents";

const Tos = () => (
    <>
        <TitleContainer title="이용약관" description="흑우촌 서비스 이용에 대한 약관입니다" />
        <TosContents />
    </>
);

export default Tos;
