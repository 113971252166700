import React from "react";
import { Navigate, useSearchParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();

  if (authenticated === null) {
    return null;
  }
  if (authenticated) {
    return children;
  }

  const redirectTo = `/login?redirectUrl=${encodeURIComponent(location.pathname)}`;
  return <Navigate to={redirectTo} />;
};

export default PrivateRoute;

const NonLoggedInRoute = ({ children }) => {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get("redirectUrl");

  if (authenticated === null) {
    return null;
  }

  return authenticated ? <Navigate to={redirectUrl || "/"} /> : children;
};

export { PrivateRoute, NonLoggedInRoute };
