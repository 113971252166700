import React from "react";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../../constants/colors";

const AfterPayment = ({ order_id, order_code, status }) => {
  return status === "success" ? (
    <div class="section-block pt-200">
      <div class="row">
        <div class="column width-10 offset-1">
          <div class="with-background">
            <div class="row">
              <div class="column width-12">
                <p class="lead">
                  Thank you for your payment of <strong>$87.00</strong> on <strong>28 Aug 2017</strong> using <strong>PayPal</strong>.
                </p>
                <p>자세한 내용은 이메일로 발송되었습니다.</p>
              </div>
              <div class="column width-12">
                <hr />
              </div>
            </div>
            <div class="row">
              <div class="column width-12">
                <h4 class="mb-30">주문번호</h4>
                <div class="box small rounded bkg-green color-white shadow">
                  <span class="order-number title-medium opacity-07">{order_id}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="column width-12">
                <h4 class="mb-30">주문 개요</h4>
                <div class="cart-overview">
                  <table class="table non-responsive rounded large mb-50">
                    <thead>
                      <tr>
                        <th class="product-details">상품명</th>
                        <th class="product-quantity center">수량</th>
                        <th class="product-subtotal right">가격</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="cart-item">
                        <td class="product-details">
                          <span>
                            <span>
                              <Link to="#" class="product-title">
                                Walnut iPad Stand - <em>$15.00</em>
                              </Link>
                              <span class="product-description">
                                <em>for 10.5, 12.9</em>
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="product-quantity center">
                          <span>x1</span>
                        </td>
                        <td class="product-subtotal right">
                          <span class="amount">$15.00</span>
                        </td>
                      </tr>
                      <tr class="cart-item">
                        <td class="product-details">
                          <span>
                            <span>
                              <Link to="#" class="product-title">
                                Mophie Powerstation XL - <em>$45.00</em>
                              </Link>
                              <span class="product-description">
                                <em>for phones and tablets</em>
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="product-quantity center">
                          <span>x1</span>
                        </td>
                        <td class="product-subtotal right">
                          <span class="amount">$45.00</span>
                        </td>
                      </tr>
                      <tr class="cart-item">
                        <td class="product-details">
                          <span>
                            <span>
                              <Link to="#" class="product-title">
                                iPhone 6 Smart Bat. Case - <em>$17.00</em>
                              </Link>
                              <span class="product-description">
                                <em> color charcoal grey</em>
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="product-quantity center">
                          <span>x1</span>
                        </td>
                        <td class="product-subtotal right">
                          <span class="amount">$17.00</span>
                        </td>
                      </tr>
                      <tr class="cart-shipping-total right">
                        <td colspan="3">
                          Shipping (US): <span class="amount">$10.00</span>
                        </td>
                      </tr>
                      <tr class="cart-order-tax right">
                        <td colspan="3">
                          부가세: <span class="amount">$0.00</span>
                        </td>
                      </tr>
                      <tr class="cart-order-total right">
                        <td colspan="3">
                          합계: <span class="amount">$87.00</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="column width-12 right">
                <Link to="/mypage/pass" style={buttonStyle} class="button rounded large text-small hover-717dfb">
                  돌아가기
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default AfterPayment;
