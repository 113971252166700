import React, { useEffect, useState } from "react";
import Logo from "../../../images/logo.png";
import LogoDark from "../../../images/logo-dark.png";
import { Link, useLocation } from "react-router-dom";
import TopbarButton from "./TopbarButton";
import TopbarLoginWidget from "./TopbarLoginWidget";
import TopbarMenuItem from "./TopbarMenuItem";
import { sitemap } from "../Layout/Sitemap";
import classNames from "classnames";
import TopbarUserInfoWidget from "./TopbarUserInfoWidget";
import TopbarCart from "./TopbarCart";
import queryString from "query-string";
import { useSelector } from "react-redux";

const Topbar = ({ type }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const pathname = location.pathname;
  const match = pathname === "/" || pathname === "/download" || pathname === "/marketplace";
  const { category } = queryString.parse(location.search);
  const auth = useSelector((state) => state.auth.isAuthenticated);

  const headerClassNames = {
    header: true,
    "header-fixed-on-mobile": true,
    "header-transparent": match,
    "header-absolute": match && !category,
    "header-relative": !match || category,
    "nav-dark": !match,
  };

  useEffect(() => {
    if (auth === true) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [auth]);

  if (category !== undefined) {
    type = "marketDetail";
  }

  return (
    <header
      className={classNames(headerClassNames)}
      data-helper-in-threshold="200"
      data-helper-out-threshold="500"
      data-sticky-threshold="200"
      data-bkg-threshold="100"
      data-compact-threshold="100"
    >
      <div className={`header-inner ${type === "marketDetail" ? "color-dark bottom-border" : ""}`}>
        <div className="row nav-bar">
          <div className="column width-12 nav-bar-inner">
            <div className="logo">
              <div className="logo-inner">
                <Link to="/" className={`${type === "marketDetail" ? "detail-dark-logo" : ""}`}>
                  <img src={LogoDark} alt="흑우촌 로고" />
                </Link>
                <Link to="/">
                  <img src={Logo} alt="흑우촌 로고" className={`${type === "marketDetail" ? "detail-logo-none" : ""}`} />
                </Link>
              </div>
            </div>
            <nav className="navigation nav-block secondary-navigation nav-right">
              <ul>
                {!isAuthenticated ? <TopbarLoginWidget /> : type !== "market" && type !== "marketDetail" ? <TopbarUserInfoWidget /> : <TopbarCart />}
                <TopbarButton />
              </ul>
            </nav>
            <nav className="navigation nav-block primary-navigation nav-center">
              <ul>
                {sitemap
                  .filter((item) => item.navigation && item.hide !== true)
                  .map((firstDepthMenu) => (
                    <TopbarMenuItem key={firstDepthMenu.path} name={firstDepthMenu.name} to={firstDepthMenu.path}>
                      {firstDepthMenu.children && (
                        <ul className="sub-menu">
                          {firstDepthMenu.children
                            .filter((item) => item.navigation)
                            .map((secondDepthMenu) => (
                              <TopbarMenuItem
                                key={secondDepthMenu.path}
                                name={secondDepthMenu.name}
                                to={firstDepthMenu.path + "/" + secondDepthMenu.path}
                              />
                            ))}
                        </ul>
                      )}
                    </TopbarMenuItem>
                  ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
