import React from "react";
import { iconStyle } from "../../constants/colors";

const AboutNode = ({ iconClass, title, description }) => {
  return (
    <div class="column width-4">
      <div class="box xlarge rounded bkg-white">
        <div class="feature-column medium">
          <span class={`feature-icon icon-${iconClass}`} style={iconStyle}></span>
          <div class="feature-text">
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutNode;
