import React from "react";

const InfoBox = ({ title, children }) => {
  return (
    <div className="column width-3 tmh-perspective">
      <div className="box large rounded bkg-white horizon content-box" data-animate-in="preset:scaleIn;duration:300ms;delay:0ms;">
        <h4>{title}</h4>
        <p>{children}</p>
      </div>
    </div>
  );
};

export default InfoBox;
