import React from "react";
import BannerSection from "../../components/Home/BannerSection";
import ActionSection from "../../components/Home/ActionSection";
import CatchphraseSection from "../../components/Home/CatchphraseSection";
import FeatureSection from "../../components/Home/FeatureSection";

const Home = () => {
  return (
    <>
      <BannerSection />
      <CatchphraseSection />
      <FeatureSection />
      <ActionSection />
    </>
  );
};

export default Home;
