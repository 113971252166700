import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../../constants/colors";
import { TbMoodEmpty } from "react-icons/tb";

const theadCategory = [
  { label: "날짜", className: "product-category text-left" },
  { label: "내역", className: "product-number text-center" },
  { label: "적립/사용 포인트", className: "product-number text-center" },
  { label: "잔액", className: "product-price-table text-right" },
];

const searchStyle = {
  ...buttonStyle,
  marginBottom: "20px",
  marginRight: "0",
};

const PointContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const itemsPerPage = 5;
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    setEndDate(`${year}-${month}-${day}`);
  }, []);

  const formatCurrency = (value) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    if (!isNaN(numericValue) && numericValue.length > 0) {
      return parseInt(numericValue).toLocaleString("ko-KR");
    } else {
      return "";
    }
  };

  const handleChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value === "") {
      setAmount("");
    } else {
      setAmount(`${formatCurrency(value)} p`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      let value = amount.replace(/[^0-9]/g, "");
      value = value.slice(0, -1);
      if (value === "") {
        setAmount("");
      } else {
        setAmount(`${formatCurrency(value)} p`);
      }
      e.preventDefault();
    }
  };

  const handleFocus = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value !== "") {
      setAmount(`${formatCurrency(value)} p`);
    }
  };

  const handleBlur = () => {
    let value = amount.replace(/[^0-9]/g, "");
    if (value !== "") {
      setAmount(`${formatCurrency(value)} p`);
    } else {
      setAmount("");
    }
  };

  const handleTabChange = (tabIndex, event) => {
    event.preventDefault();
    if (tabIndex !== activeTab) {
      setActiveTab(tabIndex);
    }
  };

  const handleLinkClick = (e, newPage) => {
    e.preventDefault();
    if (newPage < 1 || newPage > pageCount) {
      return;
    }
    setPage(newPage);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    alert("준비 중인 기능입니다.");
    /*filterData();*/
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("준비 중인 기능입니다.");
  };
  /*
  const filterData = () => {
    const filtered = dummyData.filter((item) => item.date >= startDate && item.date <= endDate);
    setFilteredData(filtered);
    setPage(1);
  };
*/
  const currentItems = filteredData.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div class="content clearfix">
      <div class="section-block replicable-content">
        <div class="row">
          <div class="column width-4">
            <h3 class="mb-50">H-Point</h3>
          </div>
          <div class="column width-8 right-align">
            <div class="tabs rounded style-3 left">
              <ul class="tab-nav point-tab">
                <li key={0} className={activeTab === 0 ? "active" : ""} onClick={(e) => handleTabChange(0, e)}>
                  <Link to="#tabs-7-pane-1">
                    <span class="icon-magnifying-glass small"></span> 포인트 조회
                  </Link>
                </li>
                {/* 포인트 선물 탭 일시적으로 숨김
                <li key={1} className={activeTab === 1 ? "active" : ""} onClick={(e) => handleTabChange(1, e)}>
                  <Link to="#tabs-7-pane-2">
                    <span class="icon-shopping-bag small" style={{ marginRight: "2px" }}></span> 포인트 선물
                  </Link>
                </li>*/}
              </ul>
              <div class="tab-panes">
                <div className={`tab-pane ${activeTab === 0 ? "active" : ""} animate animate-in`} id="tabs-7-pane-1">
                  <div className="tab-content">
                    <div
                      className={`section-block node-table bkg-white`}
                      style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                    >
                      <div className="row">
                        <div className="column width-15">
                          <div
                            class="box small rounded bkg-green color-white shadow total-point"
                            style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <span>보유 포인트</span>
                            <span class="order-number title-medium opacity-07 total-point-number">65,535 H-Point</span>
                          </div>
                          <div className="date-container">
                            <span style={{ marginTop: "10px" }}>기간</span>
                            <div className="date-input">
                              <input
                                type="date"
                                name="시작 날짜"
                                className="form-password form-element rounded medium width-5"
                                defaultValue={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                              <span className="tilde" style={{ marginTop: "10px" }}>
                                {" "}
                                ~{" "}
                              </span>
                              <input
                                type="date"
                                name="마지막 날짜"
                                className="form-password form-element rounded medium width-5"
                                defaultValue={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            </div>
                            <input
                              type="submit"
                              value="검색"
                              onClick={handleSearch}
                              className="button rounded width-2 hover-717dfb"
                              style={searchStyle}
                            />
                          </div>
                          <div className="cart-overview table-container">
                            <div className="table-wrapper">
                              {" "}
                              <table className="table non-responsive rounded large mb-50">
                                <thead className="point-thead">
                                  <tr>
                                    {theadCategory.map((item, index) => (
                                      <th key={index} className={item.className}>
                                        {item.label}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody className="payment-tbody">
                                  {currentItems.length === 0 ? (
                                    <tr>
                                      <td colSpan={theadCategory.length} className="text-center">
                                        <TbMoodEmpty size={24} />
                                        <p>포인트 적립 및 사용 내역이 없습니다.</p>
                                      </td>
                                    </tr>
                                  ) : (
                                    currentItems.map((item, index) => (
                                      <tr className="cart-item payment-tr" key={index}>
                                        <td className="product-category text-left node-table-td" style={{ width: "25%" }}>
                                          <span className="item-date">{item.date}</span>
                                        </td>
                                        <td className="product-deadline text-center" style={{ width: "25%" }}>
                                          <span className="amount">{item.event}</span>
                                        </td>
                                        <td
                                          className="product-deadline text-center"
                                          style={{
                                            width: "25%",
                                            color: item.points.includes("-") ? "red" : item.points.includes("+") ? "blue" : "inherit",
                                          }}
                                        >
                                          <span className="amount">{item.points} p</span>
                                        </td>
                                        <td className="product-price-table text-right" style={{ width: "25%" }}>
                                          <span className="amount">{item.price} p</span>
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="pagination-3" style={{ marginTop: "30px" }}>
                              <div className="row">
                                <div className="column width-15">
                                  <ul>
                                    <li>
                                      <Link
                                        className={`pagination-previous ${page === 1 ? "disabled" : ""}`}
                                        to="#"
                                        onClick={(e) => handleLinkClick(e, page - 1)}
                                        disabled={page === 1}
                                      >
                                        <span className="icon-left-open-mini"></span>
                                      </Link>
                                    </li>
                                    {Array.from({ length: pageCount }, (_, i) => (
                                      <li key={i + 1}>
                                        <Link className={page === i + 1 ? "current" : undefined} to="#" onClick={(e) => handleLinkClick(e, i + 1)}>
                                          {i + 1}
                                        </Link>
                                      </li>
                                    ))}
                                    <li>
                                      <Link
                                        className={`pagination-next ${page === pageCount ? "disabled" : ""}`}
                                        to="#"
                                        onClick={(e) => handleLinkClick(e, page + 1)}
                                        disabled={page === pageCount}
                                      >
                                        <span className="icon-right-open-mini"></span>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 1 ? "active" : ""} animate animate-in`} id="tabs-7-pane-2">
                  <div className="tab-content">
                    <div className={`section-block node-table bkg-white`}>
                      <div className="row">
                        <div className="column width-15">
                          <div class="login-form-container">
                            <form class="login-form" novalidate="" onSubmit={handleSubmit}>
                              <div class="row">
                                <div class="column width-12">
                                  <div
                                    class="box small rounded bkg-green color-white shadow total-point"
                                    style={{ display: "flex", justifyContent: "space-between" }}
                                  >
                                    <span>보유 포인트</span>
                                    <span class="order-number title-medium opacity-07 total-point-number">65,535 H-Point</span>
                                  </div>
                                  <div class="field-wrapper">
                                    <label class="color-charcoal">받을 사람의 핸들</label>
                                    <input
                                      type="text"
                                      name="받는 이"
                                      class="form-username form-element rounded medium"
                                      placeholder="받을 사람의 핸들"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="column width-12">
                                  <div className="field-wrapper">
                                    <label className="color-charcoal">보낼 금액</label>
                                    <input
                                      type="text"
                                      name="보낼 금액"
                                      className="form-password form-element rounded medium"
                                      placeholder="보낼 금액"
                                      required
                                      value={amount}
                                      onChange={handleChange}
                                      onFocus={handleFocus}
                                      onBlur={handleBlur}
                                      onKeyDown={handleKeyDown}
                                    />
                                  </div>
                                </div>
                                <div class="column width-12">
                                  <input
                                    type="submit"
                                    value="보내기"
                                    class="form-submit button rounded medium hover-717dfb mb-0"
                                    style={buttonStyle}
                                  />
                                </div>
                              </div>
                              <input type="text" name="honeypot" class="form-honeypot form-element" />
                            </form>
                            <div class="form-response show"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointContent;
