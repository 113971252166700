import { Link } from "react-router-dom";
import useHorizon from "../../hooks/useHorizon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { buttonStyle } from "../../constants/colors";

const ActionSection = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [linkTo, setLinkTo] = useState("/register");
  const slideInLeftShortAnimation = useHorizon({ preset: "slideInLeftShort", duration: "1000ms", threshold: 0.5 });
  const slideInRightShortAnimation = useHorizon({ preset: "slideInRightShort", duration: "1000ms", delay: "300ms", threshold: 0.5 });
  const auth = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (auth === true) {
      setIsAuthorized(true);
      setLinkTo("/download");
    } else {
      setIsAuthorized(false);
      setLinkTo("/register");
    }
  }, [auth]);

  return (
    <div className="section-block pt-40 pb-40" style={{ zIndex: "1" }}>
      <div className="row flex">
        <div className="column width-8 left horizon" {...slideInLeftShortAnimation}>
          <p className={isAuthorized ? "margin-top-10 lead mb-0 mb-mobile-20" : "lead mb-0 mb-mobile-20"}>
            {isAuthorized
              ? "흑우촌 메타버스에 접속하시려면 클라이언트를 다운로드하세요."
              : "지금 바로 가입하고 흑우촌 메타버스를 경험해보세요."}
            {!isAuthorized && <br />}
            {!isAuthorized && "계정 생성과 접속은 아무런 비용이 들지 않아요!"}
          </p>
        </div>
        <div className="column width-4 v-align-middle horizon right left-on-mobile" {...slideInRightShortAnimation}>
          <div>
            <Link to={linkTo} className="button rounded medium no-margins mb-0 hover-717dfb" style={buttonStyle}>
              {isAuthorized ? "다운로드" : "회원가입"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionSection;
