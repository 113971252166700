import React from "react";
import { useRoutes } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Wrapper from "./Wrapper";

const Layout = ({ sitemap }) => {
  const routes = useRoutes(sitemap);

  return (
    <>
      <Sidebar />
      <Wrapper routes={routes} />
    </>
  );
};

export default Layout;
