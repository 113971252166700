import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoDark from "../../images/logo-dark.png";
import SignInput from "../../components/Sign/SignInput";
import { validateEmail } from "../../utils/validation";
import { publicInstance } from "../../store/api/baseInstance";
import { buttonStyle } from "../../constants/colors";

const FindPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    const error = validateEmail(email);
    setEmailError(error);
  }, [email]);

  const handlePassword = async () => {
    if (emailError) {
      return;
    }

    try {
      const body = JSON.stringify({ email });
      const res = await publicInstance.post("/auth/password/certification", body);
      if (res.status === 200) {
        setSuccessMsg("발송된 메일에 따라 비밀번호를 변경해주세요.");
      }
    } catch (error) {}
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      let hasError = false;

      if (email.length === 0) {
        setEmailError("이메일을 입력해주세요.");
        hasError = true;
      } else {
        const error = validateEmail(email);
        if (error) {
          setEmailError(error);
          hasError = true;
        } else {
          setEmailError(null);
        }
      }

      if (!hasError) {
        handlePassword();
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="wrapper-inner">
        <div className="content clearfix">
          <div className="section-block replicable-content window-height bkg-heavy-rain-gradient">
            <div className="row flex v-align-middle">
              <div className="column width-6 offset-3">
                <div>
                  <div className="logo mb-50">
                    <div className="logo-inner center">
                      <Link to="/">
                        <img src={LogoDark} width="125" alt="흑우촌 로고" />
                      </Link>
                    </div>
                  </div>
                  <div className="signup-box box rounded xlarge bkg-white shadow">
                    <p className="mb-20 center">
                      비밀번호가 기억나셨나요?
                      <Link to="/login" className="fade-location">
                        로그인
                      </Link>
                    </p>
                    <div className="login-form-container">
                      <div className="row">
                        <SignInput
                          label="이메일:"
                          type="email"
                          className="email"
                          placeholder="이메일을 입력해주세요."
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          value={email}
                        />
                        {emailError && <p className="signInputErrorMsg">{emailError}</p>}
                        {successMsg && <div className="box rounded small success">{successMsg}</div>}
                        <div className="column width-12 center">
                          <input
                            type="submit"
                            value="비밀번호 찾기"
                            style={buttonStyle}
                            className="form-submit button rounded medium mb-0"
                            onClick={handlePassword}
                          />
                        </div>
                      </div>
                      <div className="form-response show"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPassword;
