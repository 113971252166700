import React from "react";

const TimelineItem = ({ title, date, description, isSection, isLeft }) => (
  <li className={`timeline-item ${isSection ? "timeline-section" : ""} ${isLeft ? "entry-left" : ""}`}>
    {isSection ? (
      <>
        <h4 className="weight-semi-bold mb-5">{title}</h4>
        <span>{date}</span>
      </>
    ) : (
      <>
        <div className="timeline-info">
          <span className="weight-semi-bold color-charcoal">{date}</span>
        </div>
        <div className="timeline-content">
          <h4 className="timeline-title weight-semi-bold color-gradient-norse-beauty">{title}</h4>
          {description && <p>{description}</p>}
        </div>
      </>
    )}
  </li>
);

export default TimelineItem;
