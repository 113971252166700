import React from "react";
import ClientMockup from "../../images/download/client-mockup.png";
import useHorizon from "../../hooks/useHorizon";
import DownloadButton from "./DownloadButton";

const DownloadSection = () => {
  const slideInRightShortAnimation = useHorizon({ preset: "slideInRightShort", duration: "1000ms", threshold: 0.1 });

  return (
    <div className="section-block feature-1 overlap-bottom pt-150 bkg-gradient-light-ocean">
      <div className="media-overlay background-cover fullscreen-pattern-4 horizon" {...slideInRightShortAnimation} />
      <div className="row flex two-columns-on-tablet">
        <div className="column width-7 v-align-middle left center-on-mobile">
          <div>
            <div className="thumbnail mb-20"></div>
            <h1 className="mb-20">
              <span className="color-white mb-5">다운로드</span>
            </h1>
            <p className="lead weight-light color-white mb-30 hide-on-mobile">
              흑우촌 메타버스에 접속하시려면 사용 환경에 맞는 클라이언트를 설치해야 합니다
            </p>
            <div className="mb-mobile-30">
              <DownloadButton href="https://nas.heuguchon.com/Heuguchon_Windows_Latest.zip" iconClass="windows-store" platform="Windows" />
              <DownloadButton href="https://nas.heuguchon.com/Heuguchon_macOS_Latest.zip" iconClass="app-store" platform="macOS" />
            </div>
          </div>
        </div>
        <div className="column width-5 right center-on-mobile">
          <div className="feature-image">
            <div className="thumbnail mb-0">
              <img src={ClientMockup} width="350" alt="흑우촌 클라이언트" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection;
