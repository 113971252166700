import React from 'react';
import { Link } from "react-router-dom";

const NotFound = () => (
    <div className="section-block bkg-pink pt-150 pb-150">
        <div className="row flex v-align-middle">
            <div className="column width-8 left">
                <h1 className="status-code-title color-white">Moo!</h1>
                <h3 className="title-medium color-white"><span
                    className="status-code weight-bold">404</span>: 페이지를 찾을 수 없습니다.</h3>
                <ul className="lead list-unstyled mb-0 color-white">
                    <li><Link to="/">홈</Link></li>
                    <li><Link to="/support">고객지원</Link></li>
                </ul>
            </div>
        </div>
    </div>
);

export default NotFound;