import React from "react";
import NetworkSupport from "./NetworkSupport";
import RPCKeyTable from "./RPCKeyTable";

const Subscribed = () => {
  return (
    <>
      <NetworkSupport />
      <RPCKeyTable />
    </>
  );
};

export default Subscribed;
