import WalkHeuguchon from "../../images/about/walk-heuguchon.gif";

import useHorizon from "../../hooks/useHorizon";

const AboutHeuguchon = () => {
  const slideInLeftShortAnimation = useHorizon({ preset: "slideInLeftShort", duration: "1000ms", threshold: 0.4 });
  const slideInUpShortAnimation = useHorizon({ preset: "slideInUpShort", duration: "1000ms", delay: "300ms", threshold: 0.6 });

  return (
    <div className="section-block feature-2 right">
      <div className="row flex">
        <div className="column width-6">
          <div className="feature-image">
            <div className="feature-image-inner center-on-mobile horizon" {...slideInLeftShortAnimation}>
              <img src={WalkHeuguchon} width="100" alt="" />
            </div>
          </div>
        </div>
        <div className="column width-5 offset-1">
          <div className="feature-content">
            <div className="feature-content-inner left horizon" {...slideInUpShortAnimation}>
              <h2 className="mb-30">흑우촌은?</h2>
              <p className="lead">흑우촌은 가상자산에 대해 소통하는 메타버스 기반의 커뮤니티입니다.</p>
              <p>
                한때 뜨거운 투자 열풍을 일으켰던 NFT 시장은 이제 가격뿐만 아니라 거래량마저 감소하고 있습니다. 시장이 성숙해지고 있다는 신호일 수도 있지만, 실적이 저조하거나 처음부터 사기성 프로젝트들이 난립하며 가격 방어에 실패하는 경우가 많습니다.
              </p>
              <p>
                특히, NFT와 메타버스를 결합한 프로젝트들이 투자만 유치하고 실제 개발은 이루어지지 않는 경우가 많아 실망을 안겨주고 있습니다. 이에 흑우촌은 NFT 활용에 대한 규제 환경을 고려하여, 직접적인 NFT 사용보다는 가상자산을 주제로 한 소통과 유저들을 위한 부가기능들에 초점을 맞춘 메타버스 공간을 마련하였습니다.
              </p>
              <p>
                흑우촌은 NFT뿐만 아니라 가상자산 전반에 걸쳐 가치 있는 논의와 실질적인 커뮤니티 활동을 할 수 있는 공간입니다. 규제를 준수하면서도 가상자산 생태계 내에서 다양한 가능성을 탐구하고, 회원들이 자유롭게 교류할 수 있는 메타버스 커뮤니티를 제공하겠습니다.
              </p>
              <div className="row">
                <div className="column width-6">
                  <h4 className="color-pink">하지 않겠습니다</h4>
                  <p>흑우촌은 단기적인 이익을 위한 토큰판매를 진행하지 않겠습니다.</p>
                </div>
                <div className="column width-6">
                  <h4 className="color-green">하겠습니다</h4>
                  <p>흑우촌은 실제로 필요한 분야에 기술을 접목하여 Web3와 메타버스의 발전에 기여하겠습니다.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHeuguchon;
