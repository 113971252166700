import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeCookie } from "../../../utils/cookie/Cookies";
import { logoutUser } from "../../../store/actions/auth";
import { selectHandleFromToken } from "../../../selectors/authSelectors";
import TextAvatar from "../TextAvatar";

const TopbarUserInfoWidget = () => {
  const token = useSelector((state) => state.auth.accessToken);
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const handle = useSelector(selectHandleFromToken);

  const handleToggle = () => {
    setVisible((current) => !current);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  const handleLogout = () => {
    removeCookie("refreshToken");
    dispatch(logoutUser({ token: token }));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setVisible(false);
  }, [location]);

  const dropDownClassNames = {
    "dropdown-list": true,
    active: visible,
  };

  const shouldApplyHoverEffect =
    location.pathname === "/about" ||
    location.pathname === "/node" ||
    location.pathname === "/event" ||
    location.pathname === "/support/notice" ||
    location.pathname === "/mypage/info" ||
    location.pathname === "/mypage/reset_password" ||
    location.pathname === "/mypage/pass" ||
    location.pathname === "/cart";

  return (
    <li>
      <div className="v-align-middle">
        <div className="dropdown" ref={dropdownRef}>
          <div onClick={handleToggle} className="nav-icon flex no-page-fade no-label-on-mobile no-margin-bottom no-padding-right dropdown-div">
            <TextAvatar username={handle} />
            <span
              className={classNames("hide-on-mobile hide-on-tablet user-avatar-handle", { "hover-effect": shouldApplyHoverEffect })}
              style={{ marginRight: "10px" }}
            >
              {handle}
            </span>
            <span className={classNames("icon-down", { rotate: visible })}></span>
          </div>
          <div className={classNames(dropDownClassNames)}>
            <ul>
              <li>
                <Link to="/mypage/info">마이페이지</Link>
              </li>
              <li>
                <Link to="#" onClick={handleLogout}>
                  로그아웃
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
};

export default TopbarUserInfoWidget;
