import React, { useEffect, useRef } from "react";
import { authInstance } from "../../../store/api/baseInstance";
import { PiMoneyWavy } from "react-icons/pi";

const PassPaymentPopup = ({ isAgreed, priceCode }) => {
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (isAgreed && !hasFetchedData.current) {
      const getOrderUrl = async () => {
        try {
          hasFetchedData.current = true;
          const response = await authInstance.post("/pass", { priceCode });
          if (response.status === 200) {
            openPaymentPopup(response.data.orderUrl);
          }
        } catch (error) {
          console.error("응답을 불러오던 중 문제가 발생하였습니다:", error);
        }
      };

      getOrderUrl();
    }
  }, [isAgreed, priceCode]);

  const openPaymentPopup = (orderUrl) => {
    const width = 600;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    const features = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,resizable=yes`;

    const successUrl = `${window.location.origin}/mypage/pass`;
    const errorUrl = `${window.location.origin}/mypage/pass`;

    let params = {
      successUrl: successUrl,
      errorUrl: errorUrl,
    };

    const url = new URL(orderUrl);
    url.search = new URLSearchParams(params).toString();

    window.open(url, "_blank", features);
  };

  const handleBackButtonClick = () => {
    window.location.href = "/mypage/pass";
  };

  return (
    <>
      <div style={{ margin: "0 auto", textAlign: "center" }}>
        <PiMoneyWavy size={80} />
        <p style={{ fontSize: "20px" }}>주문을 처리하는 중입니다 ...</p>
        <button
          onClick={handleBackButtonClick}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          뒤로가기
        </button>
      </div>
    </>
  );
};

export default PassPaymentPopup;
