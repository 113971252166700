import {Link} from "react-router-dom";

const FooterSupport = () => {
    return (
        <div className="widget">
            <h3 className="widget-title mb-30">고객지원</h3>
            <ul>
                <li><Link to="/support/notice">공지사항</Link></li>
                <li><Link to="/support/faq">FAQ</Link></li>
                <li><Link to="/support/qna">1:1 문의</Link></li>
            </ul>
        </div>
    )
}

export default FooterSupport;