import React from "react";
import { colors, iconStyle } from "../../constants/colors";

const nodeIconStyle = {
  ...iconStyle,
  border: `1px solid ${colors.primary}`,
};

const NodeAdvantages = ({ iconClass, title, description }) => {
  return (
    <div className="column width-4">
      <div className="feature-column">
        <span className={`feature-icon ${iconClass} icon-circled small mb-20`} style={nodeIconStyle}></span>
        <div className="feature-text">
          <h4>{title}</h4>
          <p className="mb-30 mb-mobile-50">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default NodeAdvantages;
