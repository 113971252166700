import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LogoDark from "../../images/logo-dark.png";
import { useEffect, useState } from "react";
import SignInput from "../../components/Sign/SignInput";
import SignCheckbox from "../../components/Sign/SignCheckbox";
import SubmitButton from "../../components/Sign/SubmitButton";
import { useDispatch } from "react-redux";
import { loginUserSuccess } from "../../store/actions/auth";
import { setCookie } from "../../utils/cookie/Cookies";
import { validateHandle, validatePassword } from "../../utils/validation";
import { publicInstance } from "../../store/api/baseInstance";

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [handle, setHandle] = useState("");
  const [password, setPassword] = useState("");
  const [rememberHandle, setRememberHandle] = useState(false);
  const [handleError, setHandleError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState("");

  const expires = new Date();
  expires.setDate(expires.getDate() + 7);

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrl = searchParams.get("redirectUrl");
      if (redirectUrl) {
        navigate(redirectUrl);
      } else if (state) {
        navigate(state);
      } else {
        navigate("/");
      }
    }
  }, [isAuthenticated, state, searchParams, navigate]);

  useEffect(() => {
    const savedHandle = localStorage.getItem("savedHandle");
    if (savedHandle) {
      setHandle(savedHandle);
      setRememberHandle(true);
    }
  }, []);

  useEffect(() => {
    const error = validateHandle(handle);
    setHandleError(error);
  }, [handle]);

  useEffect(() => {
    const error = validatePassword(password);
    setPasswordError(error);
  }, [password]);

  const handleLogin = async () => {
    const handleValidationError = validateHandle(handle);
    const passwordValidationError = validatePassword(password);

    if (handleValidationError || passwordValidationError) {
      setHandleError(handleValidationError);
      setPasswordError(passwordValidationError);
      return;
    }

    if (rememberHandle) {
      localStorage.setItem("savedHandle", handle);
    } else {
      localStorage.removeItem("savedHandle");
    }

    if (handle.length === 0 || password.length === 0) {
      if (handle.length === 0) {
        setHandleError("핸들을 입력해주세요.");
      }
      if (password.length === 0) {
        setPasswordError("비밀번호를 입력해주세요.");
      }
      return;
    }

    try {
      const body = JSON.stringify({ handle, password });

      const res = await publicInstance.post("/auth/sign-in", body);

      const { accessToken, refreshToken } = res.data;
      if (res.status === 200) {
        dispatch(loginUserSuccess(accessToken));
        setCookie("refreshToken", refreshToken, { expires });
        setIsAuthenticated(true);
      }
    } catch (error) {
      setServerErrorMsg(error.response ? error.response.data.message : error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      let hasError = false;

      if (handle.length === 0) {
        setHandleError("핸들을 입력해주세요.");
        hasError = true;
      } else {
        const error = validateHandle(handle);
        if (error) {
          setHandleError(error);
          hasError = true;
        } else {
          setHandleError(null);
        }
      }

      if (password.length === 0) {
        setPasswordError("비밀번호를 입력해주세요.");
        hasError = true;
      } else {
        const error = validatePassword(password);
        if (error) {
          setPasswordError(error);
          hasError = true;
        } else {
          setPasswordError(null);
        }
      }

      if (!hasError) {
        handleLogin();
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="wrapper-inner">
        <div className="content clearfix">
          <div className="section-block replicable-content window-height bkg-heavy-rain-gradient">
            <div className="row flex v-align-middle">
              <div className="column width-6 offset-3">
                <div>
                  <div className="logo mb-50">
                    <div className="logo-inner center">
                      <Link to="/">
                        <img src={LogoDark} width="125" alt="흑우촌 로고" />
                      </Link>
                    </div>
                  </div>
                  <div className="signup-box box rounded xlarge bkg-white shadow">
                    <p className="mb-20 center">
                      아직 회원이 아니신가요?&nbsp;
                      <Link to="/register" className="fade-location">
                        회원가입
                      </Link>
                    </p>
                    <div className="login-form-container">
                      <div className="row">
                        <SignInput
                          label="핸들:"
                          type="text"
                          onChange={(e) => setHandle(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          value={handle}
                          className="username"
                          placeholder="핸들을 입력해주세요."
                          maxLength={16}
                        />
                        {handleError && <p className="signInputErrorMsg">{handleError}</p>}
                        <SignInput
                          label="비밀번호:"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          className="password"
                          placeholder="••••••••"
                          maxLength={24}
                        />
                        {passwordError && <p className="signInputErrorMsg">{passwordError}</p>}
                        <SignCheckbox
                          id="login_checkbox"
                          checked={rememberHandle}
                          onChange={(e) => setRememberHandle(e.target.checked)}
                          login={true}
                          required={false}
                        />
                        {serverErrorMsg && <div className="box rounded small warning">{serverErrorMsg}</div>}
                        <SubmitButton value="로그인" onClick={handleLogin} />
                      </div>
                      <div className="form-response show"></div>
                    </div>
                  </div>
                  <p className="text-small center mt-20 mb-0 center">
                    <Link to="/password">비밀번호 찾기</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
