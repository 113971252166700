import { Route, Routes } from "react-router-dom";
import Welcome from "./pages/Welcome";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Layout from "./components/common/Layout";
import { sitemap } from "./components/common/Layout/Sitemap";
import FindPassword from "./pages/FindPassword";
import ResetPassword from "./pages/ResetPassword";
import { NonLoggedInRoute } from "./PrivateRoute";

const Router = () => (
  <Routes>
    <Route
      path="/login"
      element={
        <NonLoggedInRoute>
          <Login />
        </NonLoggedInRoute>
      }
    />
    <Route
      path="/register"
      element={
        <NonLoggedInRoute>
          <Register />
        </NonLoggedInRoute>
      }
    />
    <Route path="/welcome" element={<Welcome />} />
    <Route path="/password" element={<FindPassword />} />
    <Route path="/reset_password" element={<ResetPassword />} />
    <Route path="*" element={<Layout sitemap={sitemap} />} />
  </Routes>
);

export default Router;
