import { Link } from "react-router-dom";
const AccordionItem = ({ active, item, onClick }) => (
  <li className={active ? "active" : undefined}>
    <Link onClick={onClick}>
      {active && <span className="icon-minus" />}
      {!active && <span className="icon-plus" />}
      {item.question}
    </Link>
    <div id="accordion-1-panel-2">
      <div className="accordion-content" dangerouslySetInnerHTML={{ __html: item.answer }} />
    </div>
  </li>
);

export default AccordionItem;
