import { createSelector } from "reselect";
import { base64UrlDecode } from "../utils/jwt";

const selectToken = (state) => state.auth.accessToken;

const selectHandleFromToken = createSelector([selectToken], (accessToken) => {
  if (!accessToken) return null;
  const payload = accessToken.split(".")[1];
  const decodedPayload = base64UrlDecode(payload);
  return decodedPayload.handle;
});

export { selectHandleFromToken };

const selectUserIdFromToken = createSelector([selectToken], (accessToken) => {
  if (!accessToken) return null;
  const payload = accessToken.split(".")[1];
  const decodedPayload = base64UrlDecode(payload);
  return decodedPayload.userId;
});

export { selectUserIdFromToken };
