import React from "react";
import { Link } from "react-router-dom";

const DownloadButton = ({ href, iconClass, platform }) => (
  <Link className="button rounded medium bkg-charcoal bkg-hover-charcoal color-white color-hover-white shadow-hover left" to={href}>
    <span className="button-content">
      <span className={`icon-${iconClass} medium left`}></span>
      <span>
        <small>Download for</small>
        {platform}
      </span>
    </span>
  </Link>
);

export default DownloadButton;
