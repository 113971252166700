import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PassPaymentPopup from "./PassPaymentPopup";
import { authInstance } from "../../../store/api/baseInstance";
import { buttonStyle } from "../../../constants/colors";

const PassAgreement = ({ priceCode }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  useEffect(() => {
    const checkAgree = async () => {
      try {
        const response = await authInstance.get("/pass/consent");
        if (response.data.consent === true) {
          setIsAgreed(true);
        } else if (response.data.consent === false) {
          setIsAgreed(false);
        }
      } catch (error) {
        console.error(error.message);
        setIsAgreed(false);
      }
    };
    checkAgree();
  }, []);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleAgreeClick = async (event) => {
    event.preventDefault();

    if (!isChecked) {
      alert("약관에 동의해주세요.");
      return;
    }

    try {
      await authInstance.post("/pass/consent");
      setIsAgreed(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errorCode === "ALREADY_HAVE_CONSENTED") {
        setIsAgreed(true);
      } else {
        console.error("약관 동의에 실패하였습니다.:", error);
      }
    }
  };

  return (
    <div className="section-block" style={{ background: "#f5f7f9" }}>
      <div className="row">
        {isAgreed === true ? (
          <PassPaymentPopup priceCode={priceCode} isAgreed={isAgreed} />
        ) : (
          <>
            <div className="column width-4 agreement-font-resize">
              <h3>결제 서비스 및</h3>
              <h3 className="mb-50">개인정보처리 제 3자 위탁 동의</h3>
            </div>
            <div className="column width-8">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commondo consequat. Duis aute irue dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <div className="column width-12">
                <div className="field-wrapper pt-0 pb-20">
                  <input
                    id="checkbox-1"
                    className="form-element checkbox rounded"
                    type="checkbox"
                    name="passAgree[checkbox-1]"
                    required
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="checkbox-1" className="checkbox-label no-margins">
                    개인정보처리 제 3자 위탁에 동의합니다.
                  </label>
                </div>
              </div>
              <Link to="#" style={buttonStyle} className="lightbox-link button rounded medium hover-717dfb" onClick={handleAgreeClick}>
                동의
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PassAgreement;
