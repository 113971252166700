import Product1 from "../images/shop/grid/large-margins/product-1.jpg";
import Product2 from "../images/shop/grid/large-margins/product-2.jpg";
import Product3 from "../images/shop/grid/large-margins/product-3.jpg";
import Product4 from "../images/shop/grid/large-margins/product-4.jpg";
import Product5 from "../images/shop/grid/large-margins/product-5.jpg";
import Product6 from "../images/shop/grid/large-margins/product-6.jpg";
import Product7 from "../images/shop/grid/large-margins/product-7.jpg";
import Product8 from "../images/shop/grid/large-margins/product-8.jpg";
import Product9 from "../images/shop/grid/large-margins/product-9.jpg";
const products = [
  // 임시 데이터
  { id: 1, category: "cameras", image: Product1, title: "Camera Canon Eos Rebel", price: { old: "$1695.00", current: "$1199.00" }, onSale: true },
  { id: 2, category: "tablets", image: Product2, title: "iPad Pro 10.5 White - 500GB Special Edition", price: { current: "$699.00" } },
  { id: 3, category: "shoes", image: Product3, title: "Nike AirMax GPS Traking Sneakers", price: { current: "$149.00" } },
  { id: 4, category: "phones", image: Product4, title: "iPhone 8 - Lagerfeld Edition", price: { current: "$1599.00" } },
  { id: 5, category: "headphones", image: Product5, title: "Apple AirPods", price: { current: "$169.00" } },
  { id: 6, category: "speakers", image: Product6, title: "Bo BeoPlay A1 Grey", price: { current: "$278.00" } },
  { id: 7, category: "keyboards", image: Product7, title: "Apple Magic Keyboard", price: { current: "$116.00" } },
  { id: 8, category: "cameras", image: Product8, title: "Camera Canon Eos 550", price: { current: "$1578.00" } },
  { id: 9, category: "speakers", image: Product9, title: "BlueD Wireless Speaker - Black", price: { current: "$459.00" } },
  { id: 10, category: "keyboards", image: Product7, title: "Apple Magic Keyboard", price: { current: "$116.00" } },
  { id: 11, category: "cameras", image: Product8, title: "Camera Canon Eos 550", price: { current: "$1578.00" } },
  { id: 12, category: "speakers", image: Product9, title: "BlueD Wireless Speaker - Black", price: { current: "$459.00" } },
];

export default products;
