import React, { useState, useEffect, useMemo, useCallback } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import { publicInstance } from "../../store/api/baseInstance";

const Carousel = () => {
  const [slides, setSlides] = useState(1);
  const slidesScroll = Math.floor(slides);
  const [eventItems, setEventItems] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await publicInstance.get("/events?status=ONGOING");
        const events = response.data.contents;
        setEventItems(events);
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    fetchEvents();
  }, []);

  const calculateX = useCallback(() => {
    const vw = window.innerWidth * 0.635;
    const valueX = (vw - 10) / 360;

    if (valueX >= 1) {
      setSlides(valueX);
    } else {
      setSlides(1);
    }
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      calculateX();
    }, 300);

    window.addEventListener("resize", handleResize);
    calculateX();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [calculateX]);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: slides,
      slidesToScroll: slidesScroll,
    }),
    [slides, slidesScroll]
  );

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {eventItems.map((item) => (
          <Link to={`/event?id=${item.id}`} key={item.id} target="_blank">
            <div style={{ width: "fit-content" }}>
              <img className="slider-image" src={item.bannerThumbnailUrl} alt={item.title} />
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
