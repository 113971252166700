import React from "react";
import NodeAdvantages from "./NodeAdvantages";
import AboutNode from "./AboutNode";
import NetWorkSupport from "./NetworkSupport";
import BottomBanner from "./BottomBanner";
import { Link } from "react-router-dom";

const NonSubscribed = () => {
  return (
    <>
      <div className="section-block pb-0">
        <div className="row flex">
          <NodeAdvantages
            iconClass="icon-power-plug"
            title="편리함"
            description="자주 사용하는 블록체인 네트워크는 물론, 가상자산 거래소에서 신규 상장 시 필요한 거래를 위해 별도의 노드를 설정할 필요가 없습니다. 네트워크 설정과 유지 관리의 번거로움 없이 흑우촌에서 지원하는 네트워크에 즉시 접근하세요."
          />
          <NodeAdvantages
            iconClass="icon-line-graph"
            title="빠름"
            description="불특정 다수가 이용하는 공개 RPC가 아니기 때문에 트래픽 부하로부터 자유롭습니다. 원하는 시점에 트랜잭션 전송 성공 확률을 높이기 위해 쾌적한 환경에서 노드 RPC를 사용하세요."
          />
          <NodeAdvantages
            iconClass="icon-flow-tree"
            title="국내 사업자"
            description="국내 사업자로서 법정화폐를 통한 카드 자동 결제를 지원합니다. 간편한 결제 방식으로 안심하고 편리하게 서비스를 이용하세요."
          />
        </div>
      </div>
      <div className="section-block replicable-content bkg-grey-ultralight">
        <div className="row">
          <div className="column width-8 offset-2 center">
            <h3>노드 RPC가 무엇인가요?</h3>
            <p className="lead mb-50">
              블록체인 노드 RPC는 메타마스크와 같은 지갑 애플리케이션이나 서비스가 노드를 거쳐 블록체인 네트워크와 상호작용할 수 있게 해주는
              방법입니다.
            </p>
          </div>
        </div>
        <div className="row flex boxes one-column-on-tablet">
          <AboutNode
            iconClass="colours"
            title="데이터 조회"
            description="블록체인 노드 RPC를 사용하여 블록체인에 저장된 데이터나 네트워크에 대한 정보를 조회할 수 있습니다. 예를 들어, 특정 블록의 정보를 가져오거나, 특정 주소의 잔액을 가져오거나, 최근 블록의 높이를 조회할 수 있습니다."
          />
          <AboutNode
            iconClass="fingerprint"
            title="트랜잭션 전송"
            description="블록체인 노드 RPC를 통해 새로운 트랜잭션을 블록체인 네트워크에 전송할 수 있습니다. 이 과정은 외부 애플리케이션이 트랜잭션을 서명하고 RPC를 호출하면 노드가 해당 트랜잭션을 블록체인 네트워크에 전파합니다."
          />
          <AboutNode
            iconClass="tablet-mobile-combo"
            title="스마트 계약 호출"
            description="블록체인 노드 RPC를 통해 스마트 계약을 실행할 수 있습니다. 이를 통해 외부 애플리케이션은 스마트 계약의 기능을 호출하고 결과를 받을 수 있습니다."
          />
        </div>
        <div className="row">
          <div className="column width-12">
            <div className="box rounded small bkg-grey-light">
              <div className="row flex v-align-middle">
                <div className="column width-12 center mb-mobile-20">
                  <div>
                    <p className="mb-mobile-20">
                      <span className="weight-semi-bold">블록체인 노드 RPC에 대해 더 알아보시려면</span>{" "}
                      <Link to="https://www.alchemy.com/overviews/rpc-node">이곳을 확인해보세요 &rarr;</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NetWorkSupport />
      <BottomBanner />
    </>
  );
};

export default NonSubscribed;
