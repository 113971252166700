import useHorizon from "../../hooks/useHorizon";
import { Link } from "react-router-dom";
import { iconStyle } from "../../constants/colors";

const FeatureSection = () => {
  const slideInRightShort0Animation = useHorizon({ preset: "slideInRightShort", duration: "1000ms", threshold: 0.5 });
  const slideInRightShort200Animation = useHorizon({ preset: "slideInRightShort", duration: "1000ms", delay: "200ms", threshold: 0.5 });
  const slideInRightShort400Animation = useHorizon({ preset: "slideInRightShort", duration: "1000ms", delay: "400ms", threshold: 0.5 });

  return (
    <div className="section-block pt-50 pb-0 bkg-grey-ultralight" style={{ zIndex: "1" }}>
      <div className="row flex">
        <div className="column width-4">
          <div className="feature-column medium left horizon" {...slideInRightShort0Animation}>
            <span className="feature-icon icon-chat" style={iconStyle}></span>
            <div className="feature-text">
              <h4>실시간 메타버스 토론</h4>
              <p>
                가상자산에 대한 토론을 게시판이나 채팅창에서 벗어나 메타버스 공간에서 실시간으로 진행하세요. 흑우촌 메타버스에서는 아바타로 참여해 자유롭게 의견을 교환할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
        <div className="column width-4">
          <div className="feature-column medium left horizon" {...slideInRightShort200Animation}>
            <span className="feature-icon icon-game-controller" style={iconStyle}></span>
            <div className="feature-text">
              <h4>다양한 활동</h4>
              <p>
                흑우촌 메타버스에서 NFT 갤러리를 관람하거나 직접 제작해보세요. 또한, AMA(Ask Me Anything)와 같은 가상자산 관련 행사에 참여하거나 메타버스에서 열리는 다양한 이벤트에 자유롭게 참여할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
        <div className="column width-4">
          <div className="feature-column medium left horizon" {...slideInRightShort400Animation}>
            <span className="feature-icon icon-tools" style={iconStyle}></span>
            <div className="feature-text">
              <h4>부가기능 제공</h4>
              <p>
                흑우촌은 TGE(토큰 생성 이벤트) 시 클레임에 필요한 블록체인 노드 RPC 서비스와 그 외의 다양한 기능을 준비 중입니다. 앞으로 흑우촌에서 제공될 부가 기능들을 기대해 주세요!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
