import { Link } from "react-router-dom";
import { buttonStyle } from "../../constants/colors";
const SuccessAdded = ({ rpcKey, bindingIP, note, closeModal }) => {
  return (
    <>
      <div
        id="tm-lightbox"
        className="tm-lightbox tml-newsletter-modal height-auto tml-modal-mode tml-padding-small tml-swap-exit-light"
        style={{
          transition: "-webkit-transform 350ms cubic-bezier(0.42, 0, 0.58, 1) 0s",
          opacity: "1",
          visibility: "visible",
          transform: "(0px, 0px, 0px, 0px)",
        }}
      >
        <div
          id="tml-content-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div
            id="tml-content"
            className="modal-dialog scale-in scrollable-content"
            style={{
              transition: "-webkit-transform 350ms cubic-bezier(0.42, 0, 0.58, 10 0s",
              opacity: "1",
              visibility: "visible",
              width: "100%",
              maxWidth: "600px",
            }}
          >
            <div id="subscribe-modal-simple" className="pt-70 pb-50">
              <div className="row">
                <div className="column width-10 offset-1 center">
                  <h3 className="mb-10">RPC 키 생성</h3>
                  <p className="mb-30">RPC를 사용하기 위한 키가 생성되었습니다.</p>
                  <div className="signup-form-container">
                    <form>
                      <div className="row">
                        <div className="row width-8">
                          <div className="field-wrapper">
                            <input
                              type="text"
                              name="rpcKey"
                              className="form-fname form-element rounded medium"
                              placeholder="RPC Key"
                              tabIndex="1"
                              readOnly
                              value={rpcKey}
                            />
                          </div>
                        </div>
                        <div className="row width-8">
                          <div className="field-wrapper">
                            <input
                              type="text"
                              name="bindingIP"
                              className="form-element rounded medium"
                              placeholder="바인딩 IP"
                              tabIndex="2"
                              readOnly
                              value={bindingIP}
                            />
                          </div>
                        </div>
                        <div className="row width-6">
                          <div className="field-wrapper">
                            <input
                              type="text"
                              name="note"
                              className="form-element rounded medium"
                              placeholder="메모"
                              tabIndex="3"
                              readOnly
                              value={note}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="column width-12">
                          <button type="button" onClick={closeModal} style={buttonStyle} className="button rounded add-button hover-717dfb">
                            닫기
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="form-response"></div>
                  </div>
                </div>
              </div>
            </div>
            <Link to="#" id="tml-exit" className="tml-nav cursor-pointer" onClick={closeModal}></Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessAdded;
