import React from "react";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../constants/colors";

const nodeBottomStyle = {
  ...buttonStyle,
  marginRight: "5px",
};

const BottomBanner = () => {
  const loginUrl = `/login?redirectUrl=/mypage/pass`;

  return (
    <div className="section-block hero-2 hero-2-2 clear-height">
      <div className="media-overlay bkg-charcoal opacity-04"></div>
      <div className="row flex two-columns-on-tablet tmh-perspective">
        <div
          className="column width-7 left v-align-middle horizon"
          data-animate-in="preset:slideInLeftShort;duration:1000ms;delay:0;"
          data-threshold="1"
          style={{ opacity: "1", visibility: "visible" }}
        >
          <div>
            <h3 className="mb-5 color-white font-400">사용하실 준비가 되셨나요?</h3>
            <h3 className="color-white opacity-06 font-400">회원가입 후 흑우촌 패스를 구독하면 끝입니다.</h3>
          </div>
        </div>
        <div
          className="column width-5 right left-on-mobile v-align-middle horizon"
          data-animate-in="preset:slideInRightShort;duration:1000ms;delay:300;"
          data-threshold="1"
          style={{ opacity: "1", visibility: "visible" }}
        >
          <div>
            <Link to={loginUrl} className="button rounded medium mb-0 hover-717dfb" style={nodeBottomStyle}>
              흑우촌 패스 구독
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomBanner;
