import {Link} from "react-router-dom";

const FooterPolicy = () => {
    return (
        <div className="widget">
            <h3 className="widget-title mb-30">약관 및 정책</h3>
            <ul>
                <li><Link to="/tos">이용약관</Link></li>
                <li><Link to="/privacy">개인정보처리방침</Link></li>
            </ul>
        </div>
    )
}

export default FooterPolicy;