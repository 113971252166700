import React from "react";
import { Link } from "react-router-dom";

const OrderItem = ({ title, description, price, quantity }) => {
  return (
    <tr className="cart-item">
      <td className="product-details">
        <span>
          <span>
            <Link to="#" className="product-title">
              {title} - <em>₩{price}</em>
            </Link>
            <span className="product-description">
              <em>{description}</em>
            </span>
          </span>
        </span>
      </td>
      <td className="product-quantity center">
        <span>x{quantity}</span>
      </td>
      <td className="product-subtotal right">
        <span className="amount">{price}원</span>
      </td>
    </tr>
  );
};

export default OrderItem;
