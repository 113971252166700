import React, { useEffect, useState } from "react";
import { authInstance } from "../../store/api/baseInstance";
import { Link } from "react-router-dom";
import AddKeyModal from "./AddKeyModal";
import { buttonStyle } from "../../constants/colors";
import { TbMoodEmpty } from "react-icons/tb";

const theadCategory = [
  { label: "RPC키", className: "product-category text-left" },
  { label: "바인딩 IP", className: "product-number text-center" },
  { label: "생성일", className: "product-date text-center" },
  { label: "메모", className: "product-price-table text-center" },
  { label: "액션", className: "product-price-table text-right" },
];

const RPCKeyTable = () => {
  const [keyItems, setKeyItems] = useState([]);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const getKeyData = async () => {
    try {
      const response = await authInstance.get("/node/rpc/keys");
      setKeyItems(response.data);
    } catch (error) {
      console.error("node RPC 키를 가져오는데 실패하였습니다: ", error);
    }
  };

  const deleteKeyData = async (rpcKey) => {
    try {
      const response = await authInstance.delete(`/node/rpc/keys/${rpcKey}`);
      setKeyItems((prevItems) => prevItems.filter((item) => item.RPCKey !== rpcKey));
      if (response.status === 204) {
        getKeyData();
      }
    } catch (error) {
      console.error("RPC 키를 삭제하는데 실패하였습니다: ", error);
    }
  };

  useEffect(() => {
    getKeyData();
  }, []);

  const handleDelete = (rpcKey, index) => {
    setDeletingIndex(index);
    setTimeout(() => {
      deleteKeyData(rpcKey);
      setDeletingIndex(null);
    }, 100);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="section-block bkg-grey-ultralight">
        <div className="row">
          <div className="column width-15">
            <div className="flex justify-between node-modal-title">
              <h3 className="text-left font-400">RPC 키 관리</h3>
              <Link style={buttonStyle} className="button rounded hover-717dfb add-button" onClick={toggleModal}>
                생성
              </Link>
            </div>
            <div className="overflow-y-scroll">
              <div className="cart-overview table-container min-width-729">
                <table className="table non-responsive rounded large mb-50">
                  <thead className="payment-thead">
                    <tr className="color-white bkg-black">
                      {theadCategory.map((item, index) => (
                        <th key={index} className={item.className}>
                          {item.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="payment-tbody">
                    {keyItems.length === 0 ? (
                      <tr>
                        <td colSpan={theadCategory.length} className="text-center">
                          <TbMoodEmpty size={30} style={{ marginTop: "20px" }} />
                          <p>RPC 키가 존재하지 않습니다.</p>
                        </td>
                      </tr>
                    ) : (
                      keyItems.map((item, index) => (
                        <tr className={`cart-item payment-tr ${deletingIndex === index ? "slide-out" : ""}`} key={index}>
                          <td className="product-number text-left">
                            <span>{item.rpcKey}</span>
                          </td>
                          <td className="product-deadline text-center">
                            <span className="amount">{item.bindingIp}</span>
                          </td>
                          <td className="product-price-table text-center">
                            <span className="amount">{item.createdAt.split("T")[0]}</span>
                          </td>
                          <td className="product-price-table text-center">
                            <span className="amount">{item.memo}</span>
                          </td>
                          <td
                            className="product-price-table text-right"
                            onClick={() => handleDelete(item.rpcKey, index)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="icon-trash"></div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <AddKeyModal toggleModal={toggleModal} getKeyData={getKeyData} />}
    </>
  );
};

export default RPCKeyTable;
