import React, { useState } from "react";
import AccordionItem from "./AccordionItem";

const Accordion = ({ questionsAnswers }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
        const active = index === activeIndex;
        return (
            <AccordionItem
                key={item.question}
                active={active}
                item={item}
                onClick={() => handleClick(index)}
            />
        );
    });

    return (
        <div className="accordion style-2 rounded">
            <ul>
                {renderedQuestionsAnswers}
            </ul>
        </div>
    );
};

export default Accordion;
