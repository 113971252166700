import {useRef, useEffect, useCallback, useState} from 'react';

const useHorizon = ({ preset, easing, delay, duration, threshold}) => {
    const dom = useRef();
    const [mobile, setMobile] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [o, setO] = useState(0);
    const [tx, setTx] = useState(0);
    const [ty, setTy] = useState(0);
    const [tz, setTz] = useState(0);
    const [rx, setRx] = useState('0deg');
    const [ry, setRy] = useState('0deg');
    const [rz, setRz] = useState('0deg');
    const [ox, setOx] = useState('50%');
    const [oy, setOy] = useState('50%');
    const [s, setS] = useState(1);

    // Easing Array
    const easingArray = {
        'linear':'cubic-bezier(0, 0, 1, 1)',
        'swing':'cubic-bezier(0.42, 0, 0.58, 1)',
        'easeOutCubic':'cubic-bezier(.215,.61,.355,1)',
        'easeInOutCubic':'cubic-bezier(.645,.045,.355,1)',
        'easeInCirc':'cubic-bezier(.6,.04,.98,.335)',
        'easeOutCirc':'cubic-bezier(.075,.82,.165,1)',
        'easeInOutCirc':'cubic-bezier(.785,.135,.15,.86)',
        'easeInExpo':'cubic-bezier(.95,.05,.795,.035)',
        'easeOutExpo':'cubic-bezier(.19,1,.22,1)',
        'easeInOutExpo':'cubic-bezier(1,0,0,1)',
        'easeInQuad':'cubic-bezier(.55,.085,.68,.53)',
        'easeOutQuad':'cubic-bezier(.25,.46,.45,.94)',
        'easeInOutQuad':'cubic-bezier(.455,.03,.515,.955)',
        'easeInQuart':'cubic-bezier(.895,.03,.685,.22)',
        'easeOutQuart':'cubic-bezier(.165,.84,.44,1)',
        'easeInOutQuart':'cubic-bezier(.77,0,.175,1)',
        'easeInQuint':'cubic-bezier(.755,.05,.855,.06)',
        'easeOutQuint':'cubic-bezier(.23,1,.32,1)',
        'easeInOutQuint':'cubic-bezier(.86,0,.07,1)',
        'easeInSine':'cubic-bezier(.47,0,.745,.715)',
        'easeOutSine':'cubic-bezier(.39,.575,.565,1)',
        'easeInOutSine':'cubic-bezier(.445,.05,.55,.95)',
        'easeInBack':'cubic-bezier(.6,-.28,.735,.045)',
        'easeOutBack':'cubic-bezier(.175, .885,.32,1.275)',
        'easeInOutBack':'cubic-bezier(.68,-.55,.265,1.55)',

        // Special
        'easeFastSlow':'cubic-bezier(.11,.69,.66,1.01)',
        'easeBounceBack':'cubic-bezier(.16,1.36,.57,.96)',
        'easeBounceBackHard':'cubic-bezier(.8,1.91,0,.94)',
        'easeBounceIn':'cubic-bezier(.15,2.6,0,-0.2)',
        'easeSwingInOut':'cubic-bezier(.35,3.8,0.3,-0.6)'
    };

    // Animation Preset Array
    const animationPresetArray = {
        'fadeIn':'opacity: 0;easing: swing;',

        // Slide in
        'slideInUpShort' : 'opacity:0;transY: 50px;easing:easeFastSlow;',
        'slideInRightShort' : 'opacity:0;transX: 50px;easing:easeFastSlow;',
        'slideInDownShort' : 'opacity:0;transY: -50px;easing:easeFastSlow;',
        'slideInLeftShort' : 'opacity:0;transX: -50px;easing:easeFastSlow;',
        'slideInUpLong' : 'opacity:0;transY: 250px;easing:easeFastSlow;',
        'slideInRightLong' : 'opacity:0;transX: 250px;easing:easeFastSlow;',
        'slideInDownLong' : 'opacity:0;transY: -250px;easing:easeFastSlow;',
        'slideInLeftLong' : 'opacity:0;transX: -250px;easing:easeFastSlow;',

        // Bounce in
        'bounceIn' : 'opacity:0;scale:0.7;easing:easeBounceIn;',
        'bounceOut' : 'opacity:0;scale:1.4;easing:easeBounceIn;',
        'bounceInUp' : 'opacity:0;transY: 250px;easing:easeBounceIn;',
        'bounceInRight' : 'opacity:0;transX: 250px;easing:easeBounceIn;',
        'bounceInDown' : 'opacity:0;transY: -250px;easing:easeBounceIn;',
        'bounceInLeft' : 'opacity:0;transX: -250px;easing:easeBounceIn;',

        // Scale in
        'scaleIn' : 'opacity:0;scale: 0.6;easing:easeFastSlow;',
        'scaleOut' : 'opacity:0;scale: 1.4;easing:easeFastSlow',

        // Flips & Spins
        'flipInX' : 'opacity:0;rotateX: -180deg;easing:easeFastSlow;',
        'flipInY' : 'opacity:0;rotateY: -180deg;easing:easeFastSlow;',
        'spinInX' : 'opacity:0;rotateX: -540deg;easing:easeFastSlow;',
        'spinInY' : 'opacity:0;rotateY: -540deg;easing:easeFastSlow;',
        'helicopterIn' : 'opacity:0;scale: 0.6;rotateZ: -360deg;easing:easeFastSlow;',
        'helicopterOut' : 'opacity:0;scale: 1.4;rotateZ: -360deg;easing:easeFastSlow;',

        // Special
        'signSwingTop' : 'opacity:0;rotateX:-60deg;transOrigX:50%;transOrigY:0%;easing:easeSwingInOut;',
        'signSwingRight' : 'opacity:0;rotateY:-60deg;transOrigX:100%;transOrigY:50%;easing:easeSwingInOut;',
        'signSwingBottom' : 'opacity:0;rotateX:-60deg;transOrigX:50%;transOrigY:100%;easing:easeSwingInOut;',
        'signSwingLeft' : 'opacity:0;rotateY:-60deg;transOrigX:0%;transOrigY:50%;easing:easeSwingInOut;',
        'wiggleX' : 'opacity:0;rotateX:-90deg;transOrigX:50%;transOrigY:50%;easing:easeSwingInOut;',
        'wiggleY' : 'opacity:0;rotateY:-90deg;transOrigX:50%;transOrigY:50%;easing:easeSwingInOut;',
        'dropUp' : 'opacity:0;transY: 250px;rotateZ:20deg;transOrigX:50%;transOrigY:50%;easing:easeBounceBackHard;',
        'dropDown' : 'opacity:0;transY: -250px;rotateZ:-20deg;transOrigX:0%;transOrigY:0%;easing:easeBounceBackHard;',
        'rollInLeft' : 'opacity:0;transX: -250px;transY: 200px;rotateZ: -120px;transOrigX:0%;transOrigY:0%;easing:easeFastSlow;',
        'rollInRight' : 'opacity:0;transX: 250px;transY: 200px;rotateZ: 120px;transOrigX:100%;transOrigY:0%;easing:easeFastSlow;',
        'turnInRight' : 'opacity:0;transX: 250px;rotateX:20deg;rotateY:75deg;transOrigX:0%;transOrigY:0%;easing:easeBounceBack;',
        'turnInLeft' : 'opacity:0;transX: -250px;rotateX:20deg;rotateY:-75deg;transOrigX:100%;transOrigY:0%;easing:easeBounceBack;'
    };

    const animate = ( current, opacity, transX, transY, transZ, rotateX, rotateY, rotateZ, scale, transOrigX, transOrigY, duration, delay, easing ) => {
        const t = document.body || document.documentElement;
        const s = t.style;
        const property = [ 'WebkitTransform', 'MozTransform', 'OTransform', 'msTransform' ];
        var prefix;
        for( let i in property ){
            if( s[ property[ i ] ] !== undefined ){
                prefix = '-' + property[ i ].replace( 'Transform', '' ).toLowerCase();
            }
        }
        const transform = prefix + '-transform';

        // Animation magic
        current.style.transform =   'translate3d(' + transX + ', ' + transY + ', ' + transZ + ') ' +
            'rotateX(' + rotateX + ') ' +
            'rotateY(' + rotateY + ') ' +
            'rotateZ(' + rotateZ + ') ' +
            'scale3d(' + scale + ', ' + scale + ', ' + scale + ')';
        current.style.transitionProperty = transform + ', opacity';
        current.style.transformOrigin = transOrigX + ' ' + transOrigY + ' 0';
        current.style.transitionDuration = duration;
        current.style.transitionDelay = delay;
        current.style.transitionTimingFunction = easing;
        current.style.visibility = 'visible';
        current.style.opacity = opacity;
    };

    const handleScroll = useCallback(
        ([entry]) => {
            const { current } = dom;
            if (entry.isIntersecting && !scrolling) {
                animate( current, 1, 0, 0, 0, 0, 0, 0, 1, ox, oy, duration, delay, easingArray[ easing ] );
                setScrolling(true);
            }
        },
        [ox, oy, duration, delay, easing],
    );

    useEffect(() => {
        if( navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i) ){
            setMobile(true);
        }

        let observer;
        const { current } = dom;

        if (current) {
            current.parentElement.classList.add('tmh-perspective');
            animationPresetArray[preset].split(';').forEach(i => {
                if(i === '')
                    return;

                let pair = i.split( ':' );
                const k = pair[0];
                let v = pair[1];

                // Check translate unit
                const unit = v.indexOf( 'px' ) >= 0 ? 'px' : v.indexOf( '%' ) >= 0 ? '%' : 'px';

                // Clean up any possible mistakes
                v = isNaN( parseFloat( v ) ) ? v : parseFloat( v );

                // Set
                if ( k === 'opacity' ) setO(v);
                if ( k === 'scale' ) setS(v);
                if ( k === 'transX' ) setTx(v + unit);
                if ( k === 'transY' ) setTy(v + unit);
                if ( k === 'transZ' ) setTz(v + unit);
                if ( k === 'rotateX' ) setRx(v + 'deg');
                if ( k === 'rotateY' ) setRy(v + 'deg');
                if ( k === 'rotateZ' ) setRz(v + 'deg');
                if ( k === 'transOrigX' ) setOx(v + '%');
                if ( k === 'transOrigY' ) setOy(v + '%');
            })

            observer = new IntersectionObserver(handleScroll, { threshold });
            observer.observe(current);
        }

        return () => observer && observer.disconnect();
    }, [handleScroll]);

    const initialStyle = {
        backfaceVisibility: 'hidden',
        transition: mobile ? undefined : 'none',
        transform : mobile ? undefined : 'translate3d(' + tx + ', ' + ty + ', ' + tz + ' )' +
            'rotateX(' + rx + ') ' +
            'rotateY(' + ry + ') ' +
            'rotateZ(' + rz + ') ' +
            'scale3d(' + s + ', ' + s + ', ' + s + ')',
        opacity: mobile ? 1 : o,
        visibility: mobile ? 'hidden' : 'visible'
    }

    return {
        ref: dom,
        style: initialStyle
    };
};

useHorizon.defaultProps = {
    preset: 'fadeIn',
    easing: 'swing',
    delay: '0ms',
    duration: '1000ms',
    threshold: 1
}
export default useHorizon;