import React from "react";

const CodeInput = ({ label, type, onChange, placeholder, maxLength, onClick, disabled, timer }) => {
  return (
    <div className="column width-12">
      <div className="field-wrapper">
        <label className="color-charcoal">{label}</label>
        <div className="containButton">
          <input
            type={type}
            onChange={onChange}
            className="form-email form-element rounded medium authCodeInput"
            placeholder={placeholder}
            maxLength={maxLength}
            required
            readOnly={disabled}
          />
          <button className="emailConfirmButton" onClick={onClick} disabled={disabled}>
            {type === "email" ? (disabled ? `${timer}초` : "인증코드 발송") : "인증"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CodeInput;
