import TitleContainer from "../../components/common/Layout/TitleContainer";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import dayjs from "dayjs";
import { colors, iconStyle } from "../../constants/colors";
import { TbMoodEmpty } from "react-icons/tb";

const loadingStyle = {
  ...iconStyle,
  border: `1px solid ${colors.primary}`,
};

const Notice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = queryString.parse(location.search);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getArticles = async (pageNumber = 1) => {
      setLoading(true);
      try {
        const response = await axios.get(`https://heuguchon.zendesk.com/api/v2/help_center/ko/sections/4959308929423/articles`, {
          params: {
            page: pageNumber,
            per_page: 10,
          },
        });
        setArticles(response.data.articles);
        setPage(response.data.page || 1);
        setPageCount(response.data.page_count || 1);
      } catch (error) {
        console.error("공지사항을 불러오는데 실패하였습니다: ", error);
      } finally {
        setLoading(false);
      }
    };

    const getArticle = async (articleId) => {
      setLoading(true);
      try {
        const response = await axios.get(`https://heuguchon.zendesk.com/api/v2/help_center/ko/articles/${articleId}`);
        setArticle(response.data.article);
      } catch (error) {
        console.error("내용을 불러오는데 실패하였습니다: ", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      getArticle(id);
    } else {
      getArticles(page);
    }
  }, [id, page]);

  const handlePageChange = (i) => {
    if (i === page || i < 1 || i > pageCount) return;
    setPage(i);
  };

  let contents;
  if (id) {
    contents = (
      <>
        <div className="section-block clearfix pt-0 pb-0 bkg-grey-ultralight">
          <div className="row">
            <div className="column width-10 offset-1 content-inner blog-single-post">
              <article className="post">
                <div className="post-content with-background">
                  <h2 className="post-title center">{article?.title}</h2>
                  <div className="post-info center">
                    <span className="post-date">{dayjs(article?.created_at).format("YYYY-MM-DD")}</span>
                  </div>
                  <div className="post-content" dangerouslySetInnerHTML={{ __html: article?.body }} />
                </div>
              </article>
            </div>
          </div>
        </div>

        <div className="section-block pagination-3 portfolio bkg-grey-ultralight">
          <div className="row">
            <div className="column width-10 offset-1">
              <ul>
                <li>
                  <Link className="back-to-grid fade-location" to="#" onClick={() => navigate(-1)}>
                    목록
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    contents = (
      <>
        <div className="section-block clearfix pt-0 pb-0 bkg-grey-ultralight">
          <div className="row">
            <div className="column width-10 offset-1 content-inner blog-regular list">
              {articles.length === 0 ? (
                <div style={{ width: "270px", margin: "0 auto", textAlign: "center" }}>
                  <TbMoodEmpty size={100} style={{ marginTop: "50%" }} />
                  <p style={{ fontSize: "20px" }}>현재 등록된 공지사항이 없습니다.</p>
                </div>
              ) : (
                articles.map((article) => (
                  <article className="post" key={article.id}>
                    <div className="post-content with-background">
                      <h2 className="post-title">
                        <Link to={`/support/notice?id=${article.id}`}>{article.title}</Link>
                      </h2>
                      <div className="post-info">
                        <span className="post-date">{dayjs(article.created_at).format("YYYY-MM-DD")}</span>
                      </div>
                      <Link to={`/support/notice?id=${article.id}`} className="read-more">
                        더 읽어보기 →
                      </Link>
                    </div>
                  </article>
                ))
              )}
            </div>
          </div>
        </div>

        <div className="section-block pagination-3 bkg-grey-ultralight">
          <div className="row">
            <div className="column width-10 offset-1">
              <ul>
                <li>
                  <Link
                    className={`pagination-previous ${page === 1 ? "disabled" : ""}`}
                    to="#"
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    <span className="icon-left-open-mini"></span>
                  </Link>
                </li>
                {Array.from({ length: pageCount }, (_, i) => (
                  <li key={i + 1}>
                    <Link className={page === i + 1 ? "current" : undefined} to="#" onClick={() => handlePageChange(i + 1)}>
                      {i + 1}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    className={`pagination-next ${page === pageCount ? "disabled" : ""}`}
                    to="#"
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === pageCount}
                  >
                    <span className="icon-right-open-mini"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <TitleContainer title="공지사항" description="공지사항을 확인하실 수 있습니다" style={{ "bkg-grey-ultralight": true }} />

      {loading && (
        <div className="section-block replicable-content bkg-grey-ultralight">
          <div className="row">
            <div className="column width-12 center">
              <span className="icon-circled icon-cycle medium border-dribbble pulsate" style={loadingStyle} />
            </div>
          </div>
        </div>
      )}

      {!loading && contents}
    </>
  );
};

export default Notice;
