import React from "react";

const MypageInput = ({ label, type, value, onChange, className, placeholder, maxLength }) => {
  return (
    <div>
      <label className="color-charcoal left">{label}</label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={`form-${className} form-element rounded medium`}
        placeholder={placeholder}
        maxLength={maxLength}
        required
      />
    </div>
  );
};

export default MypageInput;
