import React from "react";
import TitleContainer from "../TitleContainer";
import Category from "../../Mypage/Category";
import PassContent from "../content/PassContent";

const HeuguPass = () => {
  return (
    <>
      <TitleContainer title="마이페이지" description="내 정보를 관리할 수 있습니다." style={{ "bkg-grey-ultralight": true }} />
      <div className="mypage-category">
        <Category />
      </div>
      <div className="mypage-content">
        <PassContent />
      </div>
    </>
  );
};
export default HeuguPass;
