import { Link } from "react-router-dom";

const FooterCompany = () => {
  return (
    <div className="widget">
      <h3 className="widget-title mb-30">회사</h3>
      <ul>
        <li>
          <Link to="https://www.wiselight.kr/">회사소개</Link>
        </li>
      </ul>
    </div>
  );
};

export default FooterCompany;
