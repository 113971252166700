import React from "react";

const SignErrorMessage = ({ error }) => {
    return (
        <div className="column width-12">
            <div className="field-wrapper pt-0 pb-20">
                <div className="box rounded small warning">
                    {error}
                </div>
            </div>
        </div>
    );
}

export default SignErrorMessage;
