import { Link } from "react-router-dom";
import React from "react";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { sitemap } from "../common/Layout/Sitemap";

const BreadCrumbs = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(sitemap);

  return (
    <div>
      <ul className="breadcrumb inline-block mb-0 pull-right clear-float-on-mobile">
        {breadcrumbs.map((breadcrumb, index) => {
          const isFirst = index === breadcrumbs.length - 3;
          return <li key={index}>{isFirst ? <Link to={breadcrumb.match.pathname}>{breadcrumb.breadcrumb}</Link> : <>{breadcrumb.breadcrumb}</>}</li>;
        })}
      </ul>
    </div>
  );
};

export default BreadCrumbs;
