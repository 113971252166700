import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
} from "../actions/auth";
import { call, put, takeLatest } from "redux-saga/effects";
import { handleActions } from "redux-actions";
import { getCookie } from "../../utils/cookie/Cookies";
import { logoutAPI } from "../api/logoutAPI";
import { logoutUserSuccess, logoutUserFailure } from "../actions/auth";
import { authInstance, publicInstance } from "../api/baseInstance";
import jwtDecode from "jwt-decode";

const initialState = {
  isAuthenticated: null,
  accessToken: null,
  error: null,
};

function* onLoginAsync(action) {
  try {
    const response = yield call(publicInstance.post, "/auth/login", action.payload);
    if (response.status === 200) {
      yield put({ type: LOGIN_USER_SUCCESS, payload: response });
    }
  } catch (e) {
    yield put({ type: LOGIN_USER_FAILURE, payload: e });
  }
}

function* onRegisterAsync(action) {
  try {
    const response = yield call(publicInstance.post, "/auth/register", action.payload);
    if (response.status === 200) {
      yield put({ type: REGISTER_USER_SUCCESS, payload: response });
    }
  } catch (e) {
    yield put({ type: REGISTER_USER_FAILURE, payload: e });
  }
}

function isValidJwt(token) {
  if (!token) return false;
  const parts = token.split(".");
  if (parts.length !== 3) return false;

  try {
    const payload = jwtDecode(token);
    const now = Math.floor(Date.now() / 1000);
    return payload.exp > now;
  } catch (e) {
    return false;
  }
}

function* refreshTokenSaga() {
  const refreshToken = getCookie("refreshToken");

  if (!refreshToken) {
    yield put({ type: REFRESH_TOKEN_FAILURE, isAuthenticated: false, payload: { message: "유효한 인증 토큰 없음" } });
    return;
  }

  try {
    const response = yield call(authInstance.post, "/auth/reissue", { refreshToken: refreshToken });
    if (response.status === 200) {
      yield put({ type: REFRESH_TOKEN_SUCCESS, isAuthenticated: true, payload: response.data.accessToken });
      const accessToken = response.data.accessToken;
      if (isValidJwt(accessToken)) {
        yield put({ type: REFRESH_TOKEN_SUCCESS, isAuthenticated: true, payload: accessToken });
      } else {
        yield put({ type: REFRESH_TOKEN_FAILURE, isAuthenticated: false, payload: { message: "유효하지 않거나 만료된 토큰입니다." } });
        yield put(logoutUserSuccess(refreshToken));
      }
    }
  } catch (error) {
    yield put({ type: REFRESH_TOKEN_FAILURE, isAuthenticated: false, payload: error });
  }
}

function* onLogoutAsync(action) {
  try {
    const response = yield call(logoutAPI, action.payload.token);
    if (response.status === 200) {
      yield put(logoutUserSuccess());
    }
  } catch (e) {
    yield put(logoutUserFailure(e));
  }
}

function onRegisterSuccess() {
  window.location.href = "/";
}

export function* authSaga() {
  yield takeLatest(LOGIN_USER, onLoginAsync);
  yield takeLatest(REGISTER_USER, onRegisterAsync);
  yield takeLatest(REGISTER_USER_SUCCESS, onRegisterSuccess);
  yield takeLatest(REFRESH_TOKEN_REQUEST, refreshTokenSaga);
  yield takeLatest(LOGOUT_USER, onLogoutAsync);
}

export default handleActions(
  {
    [LOGIN_USER_SUCCESS]: (state, action) => {
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload,
        error: null,
      };
    },
    [LOGIN_USER_FAILURE]: (state, action) => {
      const { message } = action.payload.response.data;
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        error: message,
      };
    },
    [REGISTER_USER_SUCCESS]: (state) => {
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        error: null,
      };
    },
    [REGISTER_USER_FAILURE]: (state, action) => {
      const { message } = action.payload.response.data;
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        error: message,
      };
    },
    [LOGOUT_USER_SUCCESS]: (state) => {
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        error: null,
      };
    },
    [LOGOUT_USER_FAILURE]: (state, action) => {
      return {
        ...state,
        error: action.payload.error,
      };
    },
    [REFRESH_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      isAuthenticated: true,
      accessToken: action.payload,
      error: null,
    }),
    [REFRESH_TOKEN_FAILURE]: (state, action) => ({
      ...state,
      isAuthenticated: false,
      accessToken: null,
      error: action.payload,
    }),
  },
  initialState
);

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_TOKEN_REQUEST:
      return { ...state, loading: true, error: null };
    case REFRESH_TOKEN_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true, accessToken: action.payload };
    case REFRESH_TOKEN_FAILURE:
      return { ...state, loading: false, isAuthenticated: false, error: action.error };
    default:
      return state;
  }
}
