import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { hidingSidebar, hidedSidebar } from "../../../store/actions/sidebar";
import { useLocation } from "react-router-dom";
import Topbar from "../Topbar/Topbar";
import Footer from "../Footer/Footer";

const Wrapper = ({ routes }) => {
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.sidebar);
  const location = useLocation();

  const wrapperClassNames = classNames({
    wrapper: true,
    "reveal-side-navigation": true,
    "element-reveal-right": sidebar === 1,
    inactive: sidebar !== 0,
    "slide-in": sidebar === 1,
  });

  const handleTransitionEnd = (event) => {
    if (event.target.className !== "wrapper reveal-side-navigation inactive") return false;
    document.body.classList.remove("aux-navigation-active");
    document.body.classList.remove("no-scroll");
    dispatch(hidedSidebar());
  };

  const handleClick = () => {
    if (sidebar !== 0) dispatch(hidingSidebar());
  };

  const topbarType = location.pathname.startsWith("/marketplace") ? "market" : "";

  return (
    <div
      className={wrapperClassNames}
      onTransitionEnd={handleTransitionEnd}
      onClick={handleClick}
      style={{ transitionTimingFunction: "cubic-bezier(0.86, 0, 0.07, 1)" }}
    >
      <div className="wrapper-inner">
        <Topbar type={topbarType} />
        <div className="content clearfix reveal-footer">{routes}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Wrapper;
