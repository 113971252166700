import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "../../components/Welcome/Carousel";
import Logo from "../../images/logo.png";
import axios from "axios";

const Welcome = () => {
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const getArticles = async () => {
      try {
        const response = await axios.get(`https://heuguchon.zendesk.com/api/v2/help_center/ko/sections/4959308929423/articles`);
        setArticles(response.data.articles);
      } catch (error) {
        console.error("공지사항을 불러오는데 실패하였습니다: ", error);
      }
    };
    getArticles();
  }, []);

  const videoRatio = () => {
    const boxHeight = window.innerHeight;
    const boxWidth = window.innerWidth;
    const boxRatio = boxHeight / boxWidth;
    const stanRatio = 1080 / 1920;

    if (boxRatio >= stanRatio) {
      setVideoSize({ width: boxHeight / stanRatio, height: boxHeight });
    } else if (boxRatio < stanRatio) {
      setVideoSize({ width: boxWidth, height: boxWidth * stanRatio });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", videoRatio);
    document.body.classList.add("welcome-no-scrollbar");
    videoRatio();

    return () => {
      window.removeEventListener("resize", videoRatio);
      document.body.classList.remove("welcome-no-scrollbar");
    };
  }, []);

  const latestArticles = articles
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, 6);
  return (
    <>
      <div className="layer1" style={{ overflow: "hidden" }}>
        <div className="tms-content">
          <div className="tms-content-inner center" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div className="welcome-title-container">
              <div className="column opacity-08">
                <h1 className="weight-light color-white mb-5 welcome-banner-h1">메타버스 기반 가상자산 커뮤니티</h1>
                <div className="clear"></div>
                <img src={Logo} alt="흑우촌 로고" className="welcome-logo-image" style={{ maxWidth: "243px", maxHeight: "85px" }} />
              </div>
            </div>
            <div className="welcome-content">
              <div className="welcome-notice-container">
                <h2>공지사항</h2>
                {latestArticles.map((article) => (
                  <Link to={`/support/notice?id=${article.id}`} target="_blank">
                    <div className="welcome-notice" key={article.id}>
                      {article.title}
                    </div>
                  </Link>
                ))}
              </div>
              <div className="welcome-event-container">
                <h2>이벤트</h2>
                <Carousel />
              </div>
            </div>
          </div>
        </div>
        <div className="video_area" style={{ width: videoSize.width, height: videoSize.height }}>
          <iframe
            title="background video"
            style={{ width: videoSize.width, height: videoSize.height }}
            src="https://player.vimeo.com/video/26507289?background=1&muted=1&autoplay=1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
