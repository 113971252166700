import { Link, useLocation } from "react-router-dom";

const TopbarLoginWidget = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const buttonClassName = searchParams.has("category") && location.pathname.startsWith("/marketplace") ? "prod-detail-page" : "";

  return (
    <li>
      <div className="v-align-middle">
        <Link to="/login" className={`button small rounded no-page-fade no-label-on-mobile no-margin-bottom ${buttonClassName}`}>
          <span className="icon-lock left"></span>
          <span>로그인</span>
        </Link>
      </div>
    </li>
  );
};

export default TopbarLoginWidget;
