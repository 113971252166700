import React from 'react';
import DownloadSection from "../../components/Download/DownloadSection";
import DownloadFAQ from "../../components/Download/DownloadFAQ";

const Download = () => (
    <>
        <DownloadSection />
        <DownloadFAQ />
    </>
);

export default Download;
