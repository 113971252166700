import TitleContainer from "../../components/common/Layout/TitleContainer";
import AboutHeuguchon from "../../components/About/AboutHeuguchon";
import AboutRoadmap from "../../components/About/AboutRoadmap";

const About = () => (
  <>
    <TitleContainer title="소개" description="흑우촌 메타버스를 소개합니다" style={{ "bkg-grey-ultralight": true }} />
    <AboutHeuguchon />
    <AboutRoadmap />
  </>
);

export default About;
