import { Link } from "react-router-dom";
const FooterSocial = () => {
  return (
    <div className="widget">
      <h3 className="widget-title mb-30">소셜 미디어</h3>
      <ul className="social-list list-horizontal">
        <li>
          <Link to="https://discord.gg/EF7zM7qFrt">
            <span className="fab fa-discord" style={{ fontSize: "1.6rem" }}></span>
          </Link>
        </li>
        <li>
          <Link to="https://www.youtube.com/@heuguchon_com">
            <span className="icon-youtube small"></span>
          </Link>
        </li>
        <li>
          <Link to="https://twitter.com/heuguchon">
            <span className="icon-twitter small"></span>
          </Link>
        </li>
        <li>
          <Link to="https://github.com/heuguchon">
            <span className="icon-github small"></span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
