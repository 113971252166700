import { handleActions } from 'redux-actions';
import {
    HIDED_SIDEBAR,
    hidedSidebar,
    HIDING_SIDEBAR,
    hidingSidebar,
    SHOW_SIDEBAR,
    showSidebar
} from "../actions/sidebar";
import { takeEvery } from "redux-saga/effects";

export function* sidebarSaga() {
    yield takeEvery(HIDED_SIDEBAR, hidedSidebar);
    yield takeEvery(SHOW_SIDEBAR, showSidebar);
    yield takeEvery(HIDING_SIDEBAR, hidingSidebar);
}

export default handleActions(
    {
        [HIDED_SIDEBAR]: (state, action) => 0,
        [SHOW_SIDEBAR]: (state, action) => 1,
        [HIDING_SIDEBAR]: (state, action) => 2,
    },
    0
)