import React, { useEffect, useState } from "react";
import Avatar from "../../../images/assets/avatar.jpg";
import { useSelector } from "react-redux";
import { selectUserIdFromToken } from "../../../selectors/authSelectors";
import InfoBox from "../InfoBox";
import { Link } from "react-router-dom";
import { authInstance } from "../../../store/api/baseInstance";
import TextAvatar from "../../common/TextAvatar";

const InfoContent = () => {
  const [handle, setHandle] = useState("");
  const [email, setEmail] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [deletedAt, setDeletedAt] = useState(null);

  const userId = useSelector(selectUserIdFromToken);
  const getUserInfo = async () => {
    try {
      const response = await authInstance.get(`/members/${userId}`);
      setHandle(response.data.handle);
      setEmail(response.data.email);
      setCreatedAt(response.data.createdAt.split("T")[0]);
      setDeletedAt(response.data.deletedAt.split("T")[0]);
    } catch (error) {
      console.error("사용자 정보를 가져오는데 실패하였습니다: ", error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const profileImgChange = (e) => {
    e.preventDefault();
    alert("준비 중인 기능입니다.");
  };

  const handleResign = async () => {
    try {
      const response = await authInstance.delete(`/members/${userId}`);
      if (response.status === 204) {
        alert("탈퇴가 완료되었습니다.");
        getUserInfo();
      }
    } catch (error) {
      alert(error.response ? error.response.data.message : error.message);
    }
  };

  return (
    <div id="documentation" className="section-block replicable-content bkg-grey-ultralight in-view">
      <div className="row flex">
        <div className="column width-6 v-align-middle">
          <div>
            <div className="lead mb-60" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <TextAvatar username={handle} style={{ width: "70px", height: "70px", fontSize: "50px", marginRight: "-5px" }} />
              <span className="mypage-userhandle">{handle}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row flex boxes two-columns-on-tablet">
        <InfoBox title="이메일">{email}</InfoBox>
        <InfoBox title="프로필 이미지">
          <Link to="#" onClick={profileImgChange}>
            변경 →
          </Link>
        </InfoBox>
        <InfoBox title="가입일">{createdAt}</InfoBox>
        <InfoBox title="회원 탈퇴">
          {deletedAt === null ? (
            <Link
              to="#"
              onClick={(e) => {
                handleResign();
                e.preventDefault();
              }}
            >
              탈퇴 →
            </Link>
          ) : (
            <span>탈퇴 중</span>
          )}
        </InfoBox>
      </div>
    </div>
  );
};

export default InfoContent;
