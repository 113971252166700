import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { publicInstance } from "../../store/api/baseInstance";
import { TbMoodEmpty } from "react-icons/tb";

const theadCategory = [
  { label: "RPC 주소", className: "product-category text-left" },
  { label: "블록 높이", className: "product-number text-center" },
  { label: "핑", className: "product-price-table text-right" },
];

const sortItemsByHeightAndPing = (a, b) => {
  if (typeof a.blockHeight === "number" && typeof b.blockHeight === "number") {
    if (b.blockHeight === a.blockHeight) {
      return parseFloat(a.ping) - parseFloat(b.ping);
    }
    return b.blockHeight - a.blockHeight;
  }
  return 0;
};

const NetWorkSupport = () => {
  const [networks, setNetworks] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [rpcData, setRpcData] = useState({});
  const [sortedRpcData, setSortedRpcData] = useState([]);
  const fetchIdRef = useRef(0);

  const getNetworkData = async () => {
    try {
      const response = await axios.get("/node_support.json");
      //const response = await axios.get("/node/support");
      if (response.status === 200) {
        setNetworks(response.data);
      }
    } catch (error) {
      console.error("지원 네트워크 데이터를 불러오는데 실패하였습니다: ", error);
    }
  };

  useEffect(() => {
    getNetworkData();
  }, []);

  useEffect(() => {
    if (networks.length > 0 && networks[activeTab] && networks[activeTab].rpcUrls) {
      const selectedNetwork = networks[activeTab];
      const currentFetchId = ++fetchIdRef.current;
      const initialData = {};
      selectedNetwork.rpcUrls.forEach((rpcUrl) => {
        initialData[rpcUrl] = { rpcUrl, blockHeight: "Loading...", ping: "Loading..." };
      });
      setRpcData(initialData);

      const controller = new AbortController();

      const fetchRpcData = () => {
        const networkType = selectedNetwork.type;
        selectedNetwork.rpcUrls.forEach(async (rpcUrl) => {
          const requestBody = {
            jsonrpc: "2.0",
            id: 0,
            method: networkType === "SOLANA" ? "getBlockHeight" : "eth_blockNumber",
            params: networkType === "SOLANA" ? null : [],
          };

          const responseField = "result";

          try {
            const startTime = performance.now();
            const response = await axios.post(rpcUrl, requestBody, { signal: controller.signal });

            const data = response.data;
            const blockHeight = networkType === "SOLANA" ? data[responseField] : parseInt(data[responseField], 16);

            const endTime = performance.now();
            const ping = ((endTime - startTime) / 1000).toFixed(3) + "s";

            if (fetchIdRef.current === currentFetchId) {
              setRpcData((prevData) => ({ ...prevData, [rpcUrl]: { rpcUrl, blockHeight, ping } }));
            }
          } catch (error) {
            if (axios.isCancel(error) || error.name === "CanceledError") {
              console.log(`${rpcUrl} 요청이 취소되었습니다.`);
            } else {
              console.error(`${rpcUrl}로부터 응답을 받지 못했습니다:`, error);
              if (fetchIdRef.current === currentFetchId) {
                setRpcData((prevData) => ({ ...prevData, [rpcUrl]: { rpcUrl, blockHeight: "Error", ping: "Error" } }));
              }
            }
          }
        });
      };

      fetchRpcData();

      const intervalId = setInterval(fetchRpcData, 30000);

      return () => {
        controller.abort();
        clearInterval(intervalId);
      };
    }
  }, [activeTab, networks]);

  useEffect(() => {
    setSortedRpcData(Object.values(rpcData).sort(sortItemsByHeightAndPing));
  }, [rpcData]);

  const handleTabChange = (tabIndex, event) => {
    event.preventDefault();
    if (tabIndex !== activeTab) {
      setActiveTab(tabIndex);
    }
  };

  return (
    <div className="section-block replicable-content">
      <div className="row">
        <div className="column width-4">
          <h3 className="mb-50 font-400">지원 네트워크</h3>
        </div>
        <div className="column width-8">
          <div className="tabs rounded left">
            <ul className="tab-nav network-tab">
              {networks.map((network, index) => (
                <li key={index} className={activeTab === index ? "active" : ""} onClick={(e) => handleTabChange(index, e)}>
                  <Link to={`#tabs-6-pane-${index}`}>
                    <img
                      src={network.iconUrl}
                      alt={network.name}
                      className="network-icon"
                      style={{ fontSize: "150%", textAlign: "center", width: "3rem", height: "3rem" }}
                    />
                  </Link>
                </li>
              ))}
            </ul>
            <div className="tab-panes node-tab-panes overflow-y-scroll">
              {networks.map((network, index) => (
                <div
                  key={index}
                  className={`tab-pane ${activeTab === index ? "active" : ""} animate animate-in`}
                  style={{ display: activeTab === index ? "block" : "none" }}
                >
                  <div className="tab-content">
                    <div className={`section-block node-table bkg-white`}>
                      <div className="row">
                        <div className="column width-15">
                          <h4 style={{ fontWeight: "500" }}>{network.name}</h4>
                          <div className="cart-overview table-container">
                            <table className="table non-responsive rounded large mb-50">
                              <thead className="payment-thead">
                                <tr>
                                  {theadCategory.map((item, index) => (
                                    <th key={index} className={item.className}>
                                      {item.label}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="payment-tbody">
                                {sortedRpcData.length === 0 ? (
                                  <tr>
                                    <td colSpan={theadCategory.length} className="text-center">
                                      <TbMoodEmpty size={20} />
                                      <p>네트워크 데이터가 존재하지 않습니다.</p>
                                    </td>
                                  </tr>
                                ) : (
                                  sortedRpcData.map((item, index) => (
                                    <tr key={index} className="cart-item payment-tr">
                                      <td className="product-category text-left node-table-td" style={{ width: "50%" }}>
                                        <span>{item.rpcUrl}</span>
                                      </td>
                                      <td className="product-deadline text-center" style={{ width: "30%" }}>
                                        <span className="amount">{item.blockHeight}</span>
                                      </td>
                                      <td className="product-price-table text-right" style={{ width: "20%" }}>
                                        <span className="amount">{item.ping}</span>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetWorkSupport;
