import React, { useState, useEffect, useRef } from "react";
import ThumbSmall2 from "../../../images/shop/cart/cart-thumb-small-2.jpg";
import ThumbSmall3 from "../../../images/shop/cart/cart-thumb-large-3.jpg";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../../constants/colors";
import { CiShoppingCart } from "react-icons/ci";

const TopbarCart = () => {
  // 임시 아이템
  const cartItems = [
    {
      id: 1,
      title: "Camera Canon Eos Rebel",
      quantity: 1,
      price: 1199.0,
      image: ThumbSmall2,
    },
    {
      id: 2,
      title: "Apple Airpods",
      quantity: 1,
      price: 169.0,
      image: ThumbSmall3,
    },
  ];

  const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownActive((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li ref={dropdownRef}>
      <div className="dropdown">
        <Link to="#" className={`nav-icon cart button no-page-fade ${isDropdownActive ? "active" : ""}`} onClick={toggleDropdown}>
          <span className="cart-indication">
            <span className="icon-shopping-bag"></span>
            <span className="badge"></span>
          </span>
        </Link>
        <ul className={`dropdown-list custom-content cart-overview ${isDropdownActive ? "active" : ""}`}>
          {cartItems.length === 0 ? (
            <div style={{ margin: "0 auto", textAlign: "center" }}>
              <CiShoppingCart size={30} />
              <p>장바구니가 비어 있습니다.</p>
            </div>
          ) : (
            cartItems.map((item) => (
              <li key={item.id} className="cart-item">
                <Link to="#" className="product-thumbnail">
                  <img src={item.image} alt="상품 이미지" />
                </Link>
                <div className="product-details">
                  <Link to="#" className="product-title">
                    {item.title}
                  </Link>
                  <span className="product-quantity">{item.quantity} x</span>
                  <span className="product-price">
                    <span className="currency">$</span>
                    {item.price.toFixed(2)}
                  </span>
                  <Link to="#" className="product-remove icon-cancel"></Link>
                </div>
              </li>
            ))
          )}
          <li className="cart-subtotal">
            Sub Total
            <span className="amount">
              <span className="currency">$</span>
              {subtotal.toFixed(2)}
            </span>
          </li>
          <li className="cart-actions">
            <Link to="/cart" className="view-cart mt-10">
              View Cart
            </Link>
            <Link to="#" style={buttonStyle} className="checkout button small rounded hover-717dfb cart-checkout">
              Checkout Now
            </Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default TopbarCart;
