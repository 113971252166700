const TosContents = () => (
    <div className="section-block">
        <div className="row">
            <div className="column width-12">
                <h3 style={{textAlign: "center", fontWeight: "bold"}}>흑우촌 서비스 이용약관</h3>
                <h4 style={{textAlign: "center", fontWeight: "bold"}}>제1장 총칙</h4>
                <h5 style={{fontWeight: "bold"}}>제1조 (목적)</h5>
                <p>본 약관은 와이즈라이트(이하 "회사")가 제공하는 메타버스 기반의 가상자산 커뮤니티 서비스인 '흑우촌'(이하 "서비스")의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
                <h5 style={{fontWeight: "bold"}}>제2조 (용어의 정의)</h5>
                <p>
                    <ol>
                        <li>"서비스"란 회사가 제공하는 가상자산 관련 메타버스 커뮤니티 서비스를 의미합니다.</li>
                        <li>"회원"이란 본 약관에 동의하고 회사가 제공하는 서비스에 가입하여 이용하는 이용자를 말합니다.</li>
                        <li>"비회원"이란 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.</li>
                        <li>"블록체인 노드 RPC 서비스"란 회원이 블록체인 네트워크에 접속하여 트랜잭션을 확인하거나 실행할 수 있도록 회사가 제공하는 서비스입니다.</li>
                        <li>"메타버스 공간"이란 회원들이 가상자산에 대해 자유롭게 토론하고, NFT 갤러리 및 커뮤니티 활동을 할 수 있는 가상 환경을 말합니다.</li>
                        <li>"흑우촌 패스"(이하 "패스")란 블록체인 노드 RPC 서비스 및 기타 부가 혜택을 이용할 수 있는 유료 구독권을 말합니다.</li>
                        <li>"H-point"란 회원이 서비스 내에서 각종 아이템 거래에 사용하거나 타 회원에게 선물하기 위하여 사용할 수 있는 재화를 말합니다.</li>
                        <li>그 외 본 약관에서 사용하는 용어의 정의는 관계법령 및 일반적인 상관례에 따릅니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제3조 (약관의 효력과 개정)</h5>
                <p>
                    <ol>
                        <li>본 약관은 서비스 화면에 게시하거나 기타의 방법으로 공지함으로써 효력이 발생합니다.</li>
                        <li>회사는 관련 법령을 위배하지 않는 범위 내에서 약관을 개정할 수 있으며, 변경된 약관은 적용일자 및 개정사유를 명시하여 시행일 7일 전부터 서비스 내 공지사항에 게시하여 고지합니다. 단, 회원에게 불리한 내용의 변경은 시행일 30일 전부터 고지합니다.</li>
                        <li>회원이 변경된 약관에 동의하지 않는 경우, 회원은 서비스 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다.</li>
                    </ol>
                </p>
                <h4 style={{textAlign: "center", fontWeight: "bold"}}>제2장 서비스의 이용</h4>
                <h5 style={{fontWeight: "bold"}}>제4조 (이용 계약의 성립)</h5>
                <p>
                    <ol>
                        <li>서비스 이용 계약은 서비스를 이용하려는 자가 본 약관에 동의하고 서비스를 이용함으로써 성립됩니다.</li>
                        <li>청소년 회원이나 피한정후견인, 피성년후견인이 서비스를 이용하려면 법정대리인의 동의를 얻어야 합니다. 또한 연령에 따라 일부 서비스 이용이 제한될 수 있습니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제5조 (서비스 가입 및 계정)</h5>
                <p>
                    <ol>
                        <li>회원은 회사가 제공하는 방식을 통해 서비스에 가입할 수 있습니다.</li>
                        <li>회원은 본인 여부를 확인하기 위한 본인인증 서비스 진행 시 진실한 정보만을 등록해야 하고, 거짓된 정보 등록으로 발생하는 손해는 회원에게 책임이 있으며, 이로 인하여 제3자로부터의 법적 쟁송이 발생할 경우 해당 회원은 회사에게 귀책사유가 없는 한 회사를 면책하고 관련하여 발생한 모든 손해를 배상하여야 합니다.</li>
                        <li>회사는 계정 및 인증 정보를 통해 회원의 본인 여부, 청소년 회원 여부 확인 및 회사의 정책 위반 회원을 식별하는 등의 회원 관리업무를 수행합니다.</li>
                        <li>회원은 자신의 계정을 성실히 관리하여야 합니다. 회원이 본인의 계정을 소홀히 관리하거나 제3자에게 이용을 승낙 및 이에 준하는 행위를 함으로써 발생하는 손해에 대하여 회사에게 귀책사유가 없는 한 모든 책임은 회원에게 있습니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제6조 (개인정보의 보호)</h5>
                <p>회사는 '개인정보보호법' 및 '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 등 관계법령에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 회사의 개인정보처리방침에 따릅니다.</p>
                <h5 style={{fontWeight: "bold"}}>제7조 (메타버스 공간의 이용)</h5>
                <p>
                    <ol>
                        <li>회사는 회원에게 메타버스 공간에서 가상자산에 대한 자유로운 토론, NFT 갤러리 운영, 다양한 커뮤니티 활동 등을 할 수 있는 서비스를 제공합니다.</li>
                        <li>회원은 메타버스 공간에서 자신의 아바타를 통해 활동할 수 있으며, 해당 아바타는 회원가입 시 생성한 계정을 기반으로 합니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제8조 (아바타 및 계정 생성)</h5>
                <p>
                    <ol>
                        <li>회원이 메타버스 공간을 이용하기 위해서는 회사가 제공하는 절차에 따라 아바타(이하 “계정”)를 생성해야 하며, 정확한 정보를 기재해야 합니다.</li>
                        <li>회원은 다음 각 호에 해당하는 내용으로 계정을 생성하거나 변경할 수 없습니다.</li>
                        <ul>
                            <li>상표권, 저작권 기타 지적재산권을 침해하는 내용</li>
                            <li>공공질서 및 미풍양속에 반하는 내용</li>
                            <li>운영자나 회사를 사칭하는 내용</li>
                            <li>타인에게 불쾌감을 주거나 욕설, 비속어, 노골적인 성 묘사가 포함된 내용</li>
                            <li>기타 타인에게 혼란을 줄 수 있는 내용</li>
                        </ul>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제9조 (메타버스 이용 수칙)</h5>
                <p>
                    <ol>
                        <li>회원은 메타버스 공간을 이용하는 동안 다음 각 호의 행위를 하여서는 안 됩니다.</li>
                        <ul>
                            <li>사행성 콘텐츠 및 도박 프로그램을 제작, 유포하는 행위</li>
                            <li>음란물의 제작, 유포, 윤락행위 알선 및 성적 수치심을 유발하는 행위</li>
                            <li>메타버스 내에서 제공되는 사이버머니를 현금으로 판매하거나 이를 유도하는 행위</li>
                            <li>타인에게 불쾌감을 주는 욕설, 비속어, 과도한 채팅 행위 등 타인의 서비스 이용을 방해하는 행위</li>
                            <li>타인의 개인정보, 권리 등을 침해하는 행위</li>
                            <li>불법 소프트웨어, 하드웨어의 사용 및 배포, 해킹 등 비정상적인 방법으로 서비스에 접속하거나 이용하는 행위</li>
                            <li>자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여에 도움을 주는 행위</li>
                            <li>기타 회사의 정책 및 관계법령에 위배되는 행위</li>
                        </ul>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제10조 (이용 수칙 위반에 대한 제재 방침)</h5>
                <p>
                    <ol>
                        <li>회사는 회원이 제9조를 위반할 경우 서비스 이용 제한, 계정 삭제 등 필요한 조치를 취할 수 있습니다.</li>
                        <li>제재의 단계는 다음과 같습니다.</li>
                        <ul>
                            <li>1차 위반: 경고</li>
                            <li>2차 위반: 강제 퇴장</li>
                            <li>3차 위반: 영구 이용 차단</li>
                        </ul>
                        <li>회사는 제재 사유를 회원에게 고지하며, 이의 제기는 서비스 내 문의를 통해 가능합니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제11조 (서비스의 제공 시간 및 중지, 변경)</h5>
                <p>
                    <ol>
                        <li>회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 서비스를 제공합니다.</li>
                        <li>회사는 시스템 정기점검 등 서비스 운영상 필요한 경우, 일정기간 동안 서비스를 일시 중지시킬 수 있으며, 이러한 경우 회사는 그 내용 및 시간을 서비스 내에 공지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
                        <li>회사는 새로운 서비스, 각종 버그 패치 등 서비스 운영 또는 기술상 필요한 경우에는 제공하고 있는 서비스의 전부 또는 일부를 수정, 추가, 폐지 등 변경할 수 있습니다. 변경될 서비스의 내용 및 일자 등에 대해서는 서비스 내에 공지함으로써 회원에 대한 통지를 대체합니다.</li>
                        <li>회사는 서비스의 기획이나 운영상 또는 회사의 긴박한 상황 등에 의해 서비스 전부를 중단할 필요가 있는 경우 서비스 내에 이를 공지하고 서비스의 제공을 중단할 수 있습니다. 단, 회사가 통제할 수 없는 사유로 인한 경우 공지없이 서비스가 중단될 수 있습니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제12조 (계약 해지)</h5>
                <p>
                    <ol>
                        <li>회원은 서비스 이용을 원하지 않는 경우, 회원탈퇴를 통하여 언제든지 이용 계약을 해지할 수 있으며 탈퇴 신청 이후에는 탈퇴 취소 및 탈퇴한 계정에 대해 복구할 수 없습니다.</li>
                        <li>회사는 회원이 서비스 탈퇴를 신청한 경우, 회원의 부정 이용 및 재가입 방지를 위해 필요한 정보를 7일간 보관 후 파기(삭제)합니다.</li>
                        <li>회원은 계정 탈퇴 완료 이후에 서비스에 재가입이 가능합니다.</li>
                    </ol>
                </p>
                <h4 style={{textAlign: "center", fontWeight: "bold"}}>제3장 블록체인 노드 RPC 서비스</h4>
                <h5 style={{fontWeight: "bold"}}>제13조 (블록체인 노드 RPC 서비스의 제공)</h5>
                <p>
                    <ol>
                        <li>회사는 회원에게 블록체인 네트워크에 접속하여 가상자산 트랜잭션을 확인 및 실행할 수 있는 유료 블록체인 노드 RPC 서비스를 제공합니다.</li>
                        <li>블록체인 노드 RPC 서비스는 패스를 구매한 회원에게만 제공됩니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제14조 (서비스 이용 제한 및 책임 제한)</h5>
                <p>
                    <ol>
                        <li>회사는 다음 각 호의 경우 회원의 블록체인 노드 RPC 서비스 이용을 제한할 수 있습니다.</li>
                        <ul>
                            <li>회원이 본 약관 또는 관련 법령을 위반한 경우</li>
                            <li>비정상적인 방법으로 서비스에 접근하거나 시스템을 위협하는 행위를 한 경우</li>
                            <li>자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우</li>
                            <li>기타 회사가 합리적으로 판단하여 서비스 이용을 제한할 필요가 있다고 인정되는 경우</li>
                        </ul>
                        <li>회사는 블록체인 노드 RPC 서비스 이용과 관련하여 발생하는 문제에 대해 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.</li>
                    </ol>
                </p>
                <h4 style={{textAlign: "center", fontWeight: "bold"}}>제4장 흑우촌 패스</h4>
                <h5 style={{fontWeight: "bold"}}>제15조 (흑우촌 패스의 정의 및 이용)</h5>
                <p>
                    <ol>
                        <li>패스는 회사가 제공하는 유료 구독권으로, 패스를 구매한 회원은 블록체인 노드 RPC 서비스 및 기타 패스 전용 기능을 이용할 수 있는 권한을 부여받습니다.</li>
                        <li>패스는 월간 또는 연간 구독 형태로 제공되며, 구독 기간에 따라 다양한 혜택과 기능이 제공될 수 있습니다.</li>
                        <li>패스에 포함된 기능은 서비스 운영 정책에 따라 추가, 변경, 또는 삭제될 수 있으며, 변경 사항은 회원에게 사전에 공지됩니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제16조 (흑우촌 패스의 구매 및 이용)</h5>
                <p>
                    <ol>
                        <li>회원은 서비스 내에서 패스를 구매하여 유료 서비스를 이용할 수 있으며, 구매 시 결제된 금액에 따라 지정된 기간 동안 패스의 권한이 부여됩니다.</li>
                        <li>패스는 월간 또는 연간 단위로 제공되며, 회원이 선택한 구독 기간에 따라 자동으로 갱신됩니다.</li>
                        <li>회원은 서비스 내 설정을 통해 패스의 자동 갱신을 해지할 수 있으며, 해지 후에는 다음 결제일부터 자동 갱신이 중단됩니다.</li>
                        <li>구독 기간 중에는 패스의 기능이 유지되며, 구독 기간 만료 또는 해지 시 해당 기능의 이용 권한이 종료됩니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제17조 (환불 규정)</h5>
                <p>
                    <ol>
                        <li>구매한 패스는 환불이 불가합니다. 다만, 서비스 미사용 시에 한하여 환불이 가능합니다.</li>
                        <ul>
                            <li>환불 가능 여부는 패스 구매 후 해당 기간 동안 블록체인 노드 RPC 서비스를 비롯한 패스 전용 기능을 전혀 사용하지 않은 경우에 한정됩니다.</li>
                        </ul>
                        <li>회원은 환불 요청을 서비스 내 문의를 통해 접수할 수 있으며, 회사는 회원의 서비스 이용 내역을 확인한 후 환불 절차를 진행합니다.</li>
                        <li>환불 요청이 승인되면 회사는 접수 후 10일 이내에 환불을 처리합니다.</li>
                        <li>환불은 결제 시 사용한 수단으로 진행되며, 동일한 수단으로 환불이 불가능한 경우에는 회사가 회원에게 별도의 방법을 안내합니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제18조 (자동결제 및 해지)</h5>
                <p>
                    <ol>
                        <li>패스는 자동결제 방식으로 제공되며, 구독 기간이 종료되기 전에 회원이 해지하지 않을 경우 자동으로 갱신됩니다.</li>
                        <li>회원은 서비스 내 설정을 통해 언제든지 자동결제를 해지할 수 있으며, 해지 시 다음 결제일부터 자동결제가 중단됩니다.</li>
                        <li>자동결제 해지 후에도 이미 결제된 패스는 해당 구독 기간이 종료될 때까지 이용할 수 있습니다.</li>
                    </ol>
                </p>
                <h4 style={{textAlign: "center", fontWeight: "bold"}}>제5장 기타</h4>
                <h5 style={{fontWeight: "bold"}}>제19조 (저작권 및 지적재산권)</h5>
                <p>
                    <ol>
                        <li>서비스 내에 포함된 텍스트, 그래픽, 음성, 영상 등 모든 콘텐츠에 대한 저작권 및 기타 지적재산권은 회사에 귀속됩니다.</li>
                        <li>회원은 서비스를 이용함으로써 얻은 정보 중 회사 또는 제3자의 저작권이 있는 정보를 회사의 사전 승낙 없이 복제, 배포, 출판, 방송 등의 방법으로 사용하거나 제3자에게 제공하여서는 안 됩니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제20조 (손해배상)</h5>
                <p>회원이 본 약관에서 정한 사항을 위반함으로써 회사 또는 제3자에게 손해를 입힌 경우 회원은 회사 또는 제3자에게 그 손해를 배상하여야 합니다.</p>
                <h5 style={{fontWeight: "bold"}}>제21조 (책임제한)</h5>
                <p>
                    <ol>
                        <li>회사는 서비스의 이용장애 혹은 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 중과실로 인한 경우를 제외하고는 서비스의 이용장애, 변경 및 중지로 인하여 회원에게 발생하는 손해에 대해서는 책임이 면제됩니다.</li>
                        <li>회사는 회원이 서비스를 이용하여 기대하는 점수, 순위 등을 얻지 못한 것에 대해 회사의 귀책사유가 없는 한 책임이 면제됩니다.</li>
                        <li>회사는 회원이 본인의 개인정보 등(계정 포함)을 변경하여 얻는 불이익 및 정보 상실에 대해서는 책임이 면제됩니다.</li>
                        <li>회사는 회원 상호간 또는 회원과 제3자 간에 서비스를 매개로 발생한 분쟁에 대해 회사의 귀책사유가 없는 한 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>제22조 (분쟁 해결 및 관할법원)</h5>
                <p>
                    <ol>
                        <li>회사와 회원 간의 서비스 이용과 관련하여 발생한 분쟁에 대해 회사와 회원은 성실히 협의하여 해결하도록 노력합니다.</li>
                        <li>분쟁이 해결되지 않을 경우, 관할 법원은 회사의 본사 소재지 관할 법원으로 합니다.</li>
                    </ol>
                </p>
                <h5 style={{fontWeight: "bold"}}>부칙</h5>
                <p>본 약관은 2024년 10월 02일부터 시행됩니다.</p>
            </div>
        </div>
    </div>
);

export default TosContents;