import { Link } from "react-router-dom";
import LogoDark from "../../images/logo-dark.png";
import { useEffect, useState } from "react";
import SignInput from "../../components/Sign/SignInput";
import SignCheckbox from "../../components/Sign/SignCheckbox";
import SignErrorMessage from "../../components/Sign/SignErrorMessage";
import SubmitButton from "../../components/Sign/SubmitButton";
import CodeInput from "../../components/Register/CodeInput";
import { useNavigate } from "react-router-dom";

import { validateRegisterHandle, validateEmail, validatePassword, validateConfirmPassword } from "../../utils/validation";
import { publicInstance } from "../../store/api/baseInstance";

const Register = () => {
  const [handle, setHandle] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [handleError, setHandleError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordConfirmError, setPasswordConfirmError] = useState(null);
  const [error, setError] = useState(null);
  const [useAgree, setUseAgree] = useState(false);
  const [personalDataAgree, setPersonalDataAgree] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [codeDisabled, setCodeDisabled] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();
  const [serverErrorMsg, setServerErrorMsg] = useState("");

  useEffect(() => {
    if (isDone) {
      navigate("/");
    }
  }, [isDone, navigate]);

  useEffect(() => {
    const error = validateRegisterHandle(handle);
    setHandleError(error);
  }, [handle]);

  useEffect(() => {
    const error = validateEmail(email);
    setEmailError(error);
  }, [email]);

  useEffect(() => {
    const error = validatePassword(password);
    setPasswordError(error);
  }, [password]);

  useEffect(() => {
    const error = validateConfirmPassword(passwordConfirm, password);
    setPasswordConfirmError(error);
  }, [passwordConfirm, password]);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setEmailDisabled(false);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleSendCode = async () => {
    try {
      const body = JSON.stringify({ email });
      const res = await publicInstance.post("/auth/certification", body);
      if (res.status === 200) {
        setCodeDisabled(false);
        setEmailDisabled(true);
        setTimer(60);
      }
    } catch (error) {
      alert(error.response ? error.response.data.message : error.message);
    }
  };

  const handleConfirmEmail = async () => {
    try {
      const body = JSON.stringify({
        code,
        email,
      });
      const res = await publicInstance.post("/auth/verify", body);
      if (res.status === 200) {
        setEmailDisabled(true);
        setCodeDisabled(true);
        alert("인증되었습니다.");
      }
    } catch (error) {
      alert(error.response ? error.response.data.message : error.message);
    }
  };

  const handleRegister = async () => {
    if (handle.length === 0 || email.length === 0 || password.length === 0 || passwordConfirm.length === 0) {
      if (handle.length === 0) {
        setHandleError("핸들을 입력해주세요.");
        return;
      } else if (email.length === 0) {
        setError("이메일 인증을 완료해주세요.");
        return;
      } else if (password.length === 0) {
        setPasswordError("비밀번호를 입력해주세요.");
        return;
      } else if (passwordConfirm.length === 0) {
        setPasswordConfirmError("비밀번호를 확인해주세요.");
        return;
      }
    }

    if (!useAgree) {
      setError("이용약관에 동의해주세요.");
      return;
    }

    if (!personalDataAgree) {
      setError("개인정보 처리방침에 동의해주세요.");
      return;
    }

    if (handleError || emailError || passwordError || error) {
      return;
    }

    try {
      const body = JSON.stringify({
        handle,
        email,
        password,
        passwordConfirm,
        termsOfServiceAccepted: true,
      });
      const res = await publicInstance.post("/auth/sign-up", body);
      if (res.status === 200) {
        setIsDone(true);
      }
    } catch (error) {
      setServerErrorMsg(error.response ? error.response.data.message : error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let hasError = false;

      if (handle.length === 0) {
        setHandleError("핸들을 입력해주세요.");
        hasError = true;
      } else {
        const error = validateRegisterHandle(handle);
        if (error) {
          setHandleError(error);
          hasError = true;
        } else {
          setHandleError(null);
        }
      }

      if (password.length === 0) {
        setPasswordError("비밀번호를 입력해주세요.");
        hasError = true;
      } else {
        const error = validatePassword(password);
        if (error) {
          setPasswordError(error);
          hasError = true;
        } else {
          setPasswordError(null);
        }
      }

      if (passwordConfirm.length === 0) {
        setPasswordConfirmError("비밀번호를 확인해주세요.");
        hasError = true;
      } else {
        const error = validateConfirmPassword(passwordConfirm);
        if (error) {
          setPasswordConfirmError(error);
          hasError = true;
        } else {
          setPasswordConfirmError(null);
        }
      }

      if (email.length === 0) {
        setEmailError("이메일 인증을 완료해주세요.");
        hasError = true;
      } else {
        const error = validateEmail(email);
        if (error) {
          setEmailError(error);
          hasError = true;
        } else {
          setEmailError(null);
        }
      }

      if (!useAgree) {
        setError("이용약관에 동의해주세요.");
        hasError = true;
      } else {
        setError(null);
      }

      if (!personalDataAgree) {
        setError("개인정보 처리방침에 동의해주세요.");
        hasError = true;
      } else {
        setError(null);
      }

      if (!hasError) {
        handleRegister();
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="wrapper-inner">
        <div className="content clearfix">
          <div className="section-block replicable-content window-height bkg-heavy-rain-gradient">
            <div className="row flex v-align-middle">
              <div className="column width-6 offset-3">
                <div>
                  <div className="logo mb-50">
                    <div className="logo-inner center">
                      <Link to="/">
                        <img src={LogoDark} width="125" alt="흑우촌 로고" />
                      </Link>
                    </div>
                  </div>
                  <div className="signup-box box rounded xlarge bkg-white shadow">
                    <p className="mb-20 center">
                      이미 회원이신가요?&nbsp;
                      <Link to="/login" className="fade-location">
                        로그인
                      </Link>
                    </p>
                    <div className="register-form-container">
                      <div className="row">
                        <SignInput
                          label="핸들:"
                          type="text"
                          onChange={(e) => setHandle(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          className="username"
                          placeholder="핸들을 입력해주세요."
                          maxLength={16}
                        />
                        {handleError && <p className="signInputErrorMsg">{handleError}</p>}
                        <CodeInput
                          label="이메일:"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          className="email"
                          placeholder="이메일을 입력해주세요."
                          onClick={handleSendCode}
                          disabled={emailDisabled}
                          timer={timer}
                        />
                        {emailError && <p className="signInputErrorMsg">{emailError}</p>}
                        <CodeInput
                          label="인증코드:"
                          type="text"
                          onChange={(e) => setCode(e.target.value)}
                          placeholder="인증코드를 입력해주세요."
                          onClick={handleConfirmEmail}
                          disabled={codeDisabled}
                        />
                        <SignInput
                          label="비밀번호:"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          className="password"
                          placeholder="••••••••"
                          maxLength={24}
                        />
                        {passwordError && <p className="signInputErrorMsg">{passwordError}</p>}
                        <SignInput
                          label="비밀번호 확인:"
                          type="password"
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          className="password"
                          placeholder="••••••••"
                          maxLength={24}
                        />
                        {passwordConfirmError && <p className="signInputErrorMsg">{passwordConfirmError}</p>}
                        <SignCheckbox
                          id="agree_checkbox_1"
                          onChange={(e) => setUseAgree(e.target.checked)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          login={false}
                          required={true}
                          termsOfUse={true}
                        />
                        <SignCheckbox
                          id="agree_checkbox_2"
                          onChange={(e) => setPersonalDataAgree(e.target.checked)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          login={false}
                          required={true}
                          termsOfUse={false}
                        />
                        {error && <SignErrorMessage error={error} />}
                        {serverErrorMsg && <div className="box rounded small warning">{serverErrorMsg}</div>}
                        <SubmitButton value="회원가입" onClick={handleRegister} />
                      </div>
                      <div className="form-response show"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
