import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const SelectCategory = ({ setFilteredCategory, filteredProducts }) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("진행중");
  const dropdownRef = useRef(null);

  const categoryDisplayNames = {
    모두: "모든 이벤트",
    예정: "예정된 이벤트",
    진행중: "진행 중인 이벤트",
    마감: "마감된 이벤트",
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownActive(!isDropdownActive);
  };

  const handleCategoryChange = (e, category) => {
    e.preventDefault();
    setSelectedCategory(category);
    setFilteredCategory(category);
    setIsDropdownActive(false);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="section-block pt-0 pb-0 grid-filter-dropdown" data-target-grid="#product-grid">
      <div className="freeze pt-10 bkg-white" data-extra-space-top="60" data-extra-space-bottom="0">
        <div className="row">
          <div className="column width-12">
            <div className="filter-menu-inner">
              <div className="row flex">
                <div className="column width-8 v-align-middle">
                  <div className="product-result-count">
                    <p className="mb-0 mb-mobile-30">
                      {categoryDisplayNames[selectedCategory]} ({filteredProducts.length})
                    </p>
                  </div>
                </div>
                <div className="column width-4 right left-on-mobile">
                  <span className="dropdown-label mb-0 mb-mobile-30">필터: </span>
                  <div ref={dropdownRef} className="dropdown medium inline-block" data-update-selection>
                    <Link
                      to="#"
                      className={`button rounded center border-grey-light color-grey bkg-hover-theme color-hover-white ${isDropdownActive ? "active" : ""}`}
                      onClick={toggleDropdown}
                    >
                      <span>{selectedCategory}</span>
                      <span className="icon-down-open-mini pull-right"></span>
                    </Link>
                    <ul className={`dropdown-list ${isDropdownActive ? "active" : ""}`}>
                      {["모두", "예정", "진행중", "마감"].map((category) => (
                        <li key={category}>
                          <Link className={selectedCategory === category ? "active" : ""} to="#" onClick={(e) => handleCategoryChange(e, category)}>
                            {category}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCategory;
