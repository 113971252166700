import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./scss/core.scss";
import "./scss/skin.scss";

import { history } from "./helper/history";
import Router from "./Router";
import ScrollToTop from "./components/ScrollTop";
import { refreshTokenRequest } from "./store/actions/auth";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshTokenRequest());
  }, [dispatch]);

  return (
    <BrowserRouter history={history}>
      <ScrollToTop />
      <Router />
    </BrowserRouter>
  );
}

export default App;
