import React from "react";
import TitleContainer from "../../components/common/Layout/TitleContainer";
import { useSelector } from "react-redux";
import NonSubscribed from "../../components/NodeRPC/NonSubscribed";
import Subscribed from "../../components/NodeRPC/Subscribed";

const NodeRPC = () => {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);

  let contents;
  if (!authenticated) {
    contents = <NonSubscribed />;
  } else {
    contents = <Subscribed />;
  }

  return (
    <>
      <TitleContainer title="노드 RPC" description="흑우촌 노드 RPC를 통해 블록체인에 손쉽게 접근하세요" style={{ "bkg-grey-ultralight": true }} />
      {contents}
    </>
  );
};

export default NodeRPC;
