import React, { useState, useEffect, useRef } from "react";
import InfoBox from "../InfoBox";
import { Link } from "react-router-dom";
import { authInstance } from "../../../store/api/baseInstance";

const PassInfo = ({ toggleModal, subscribeId, isRestarted }) => {
  const [passName, setPassName] = useState(null);
  const [subscribeStatus, setSubscribeStatus] = useState("");
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const [intervalCount, setIntervalCount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const hasFetchedData = useRef(false);

  const fetchSubscriptionData = async () => {
    try {
      const response = await authInstance.get("/pass");
      const data = response.data;

      const formatDate = (dateTime) => {
        return dateTime ? dateTime.split("T")[0] : "-";
      };

      setPassName(data.planName || "");
      setNextPaymentDate(formatDate(data.nextPaymentDateTime));
      setIntervalCount(data.recurring?.intervalCount || 0);
      setPaymentMethod(data.paymentMethod?.paymentInfo || "");
      setSubscribeStatus(data.status || "");
    } catch (error) {
      console.error("구독 정보를 불러오는데 실패하였습니다: ", error);
      setPassName("");
      setNextPaymentDate("-");
      setIntervalCount(0);
      setPaymentMethod("");
      setSubscribeStatus("");
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, [subscribeStatus]);

  if (isRestarted === false) {
    fetchSubscriptionData();
  }

  const handleClick = (event) => {
    event.preventDefault();
    toggleModal();
  };

  const fetchChangeMethod = async () => {
    if (subscribeId && !hasFetchedData.current) {
      try {
        hasFetchedData.current = true;
        const response = await authInstance.put(`/pass/${subscribeId}/payment-method`);
        if (response.status === 200) {
          openChangeMethodPopup(response.data.orderUrl);
        }
      } catch (error) {
        console.error("결제 수단 변경에 실패하였습니다: ", error);
      }
    }
  };

  const openChangeMethodPopup = (orderUrl) => {
    const width = 600;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    const features = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,resizable=yes`;

    const successUrl = `${window.location.origin}/mypage/pass`;

    let params = {
      successUrl: successUrl,
    };

    const url = new URL(orderUrl);
    url.search = new URLSearchParams(params).toString();

    window.open(url, "_blank", features);
  };

  const fetchActiveSubscribe = async () => {
    if (subscribeId) {
      try {
        const response = await authInstance.post(`/pass/${subscribeId}/active`);
        if (response.status === 200) {
          fetchSubscriptionData();
          alert("구독을 재개합니다.");
        }
      } catch (error) {
        console.error("구독 상태 변경에 실패하였습니다: ", error);
      }
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    fetchChangeMethod();
  };

  const handleReactiveClick = (e) => {
    e.preventDefault();
    fetchActiveSubscribe();
  };

  return (
    <div id="documentation" className="section-block replicable-content bkg-grey-ultralight in-view pass-info">
      <div className="row flex boxes two-columns-on-tablet">
        <InfoBox title="내 플랜">
          {passName}
          {subscribeStatus === "ACTIVE" && (
            <span className="label rounded pull-right" style={{ fontSize: "1.3rem", backgroundColor: "#DEF6D0", color: "#4B743F" }}>
              {" "}
              활성
            </span>
          )}
          {subscribeStatus === "PENDING_CANCEL" && (
            <span className="label rounded pull-right" style={{ fontSize: "1.3rem", backgroundColor: "#D2D2D3", color: "#676768" }}>
              {" "}
              취소 중
            </span>
          )}
          <br />
          {subscribeStatus === "ACTIVE" && (
            <Link to="#" onClick={handleClick}>
              해지 →
            </Link>
          )}
          {subscribeStatus === "PENDING_CANCEL" && (
            <Link to="#" onClick={handleReactiveClick}>
              재개 →
            </Link>
          )}
        </InfoBox>
        <InfoBox title="다음 결제 예정일">{nextPaymentDate}</InfoBox>
        <InfoBox title="구독 주기">{intervalCount} 개월</InfoBox>
        <InfoBox title="결제수단">
          {paymentMethod}
          <br />
          <Link to="#" onClick={handleButtonClick}>
            변경 →
          </Link>
        </InfoBox>
      </div>
    </div>
  );
};

export default PassInfo;
