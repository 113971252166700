import React from "react";
import { Link } from "react-router-dom";
const PlanCard = ({ planTitle, price, interval, description, gradientClass, onSelect }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onSelect();
  };
  return (
    <div className="column width-4 left center">
      <div className="pricing-table rounded large style-3 columns-1 mb-0 border-grey-light">
        <div className="pricing-table-column">
          <div className={`pricing-table-header ${gradientClass}`}>
            <h2 className="weight-semi-bold pull-left">{planTitle}</h2>
            <div className="pricing-table-price pull-right">
              <h4>
                <span className="currency">₩</span>
                {price}
                <span className="interval mt-10 text-right">{interval}</span>
              </h4>
            </div>
          </div>
          <hr className="no-margins" />
          <div className="pricing-table-text bkg-white">
            <p className="lead">{description}</p>
          </div>
          <div className="pricing-table-footer bkg-white">
            <Link href="#" onClick={handleClick} className="button rounded bkg-grey-light bkg-hover-theme color-grey color-hover-white mb-mobile-40">
              선택
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
