import React from "react";

const ItemTable = ({ tableType, theadCategory, items, theadColor }) => {
  return (
    <div className={`section-block ${tableType === "node" ? "node-table" : ""} ${theadColor === "black" ? "bkg-grey-ultralight" : "bkg-white"}`}>
      <div className="row">
        <div className="column width-15">
          {tableType !== "node" && <div className="pass-table-title">결제 내역</div>}
          <div className="cart-overview table-container">
            <table className="table non-responsive rounded large mb-50">
              <thead className="payment-thead">
                <tr className={`${theadColor === "black" ? "color-white bkg-black" : ""}`}>
                  {theadCategory.map((item, index) => (
                    <th key={index} className={item.className}>
                      {item.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="payment-tbody">
                {items.map((item, index) => (
                  <tr className="cart-item payment-tr" key={index}>
                    <td className={`product-category text-left ${tableType === "node" ? "node-table-td" : ""} `}>
                      <span>{tableType === "node" ? item.url : item.paymentDate}</span>
                    </td>
                    {tableType !== "node" && (
                      <td className="product-number text-left">
                        <span>{item.description}</span>
                      </td>
                    )}
                    {tableType !== "node" && (
                      <td className="product-date text-center">
                        <span>{item.paymentMethod}</span>
                      </td>
                    )}
                    <td className="product-deadline text-center">
                      <span className="amount">{tableType === "node" ? item.height : item.paidAmount}</span>
                    </td>
                    <td className="product-price-table text-right">
                      <span className="amount">{tableType === "node" ? item.ping : item.status}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemTable;
