import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import MarketMain from "../../components/Market/MarketMain";
import ProductDetail from "../../components/Market/ProductDetail";

const Marketplace = () => {
  const location = useLocation();
  const { category } = queryString.parse(location.search);

  let contents;
  if (category) {
    contents = (
      <>
        <ProductDetail />
      </>
    );
  } else {
    contents = (
      <>
        <MarketMain />
      </>
    );
  }
  return <>{contents}</>;
};

export default Marketplace;
