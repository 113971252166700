import React from "react";
import { Link } from "react-router-dom";

const SignCheckbox = ({ id, checked, onChange, login, termsOfUse, required, onKeyDown }) => {
  return (
    <div className="column width-12">
      <div className="field-wrapper pt-0 pb-20">
        <input
          id={id}
          className="form-element checkbox rounded"
          name="login[checkbox-1]"
          type="checkbox"
          checked={checked}
          onChange={onChange}
          onKeyDown={onKeyDown}
          required={required ? true : false}
          termsOfUse
        />
        <label htmlFor={id} className="checkbox-label no-margins">
          {login && "아이디 저장"}
          {termsOfUse && (
            <>
              <Link to="/tos" target="_blank">
                흑우촌 이용약관
              </Link>{" "}
              동의
            </>
          )}
          {!login && !termsOfUse && (
            <>
              <Link to="https://plip.kr/pcc/80d8d52b-2356-405c-9028-db0e566cf891/consent/2.html" target="_blank">
                개인정보 수집 및 이용
              </Link>{" "}
              동의
            </>
          )}
        </label>
      </div>
    </div>
  );
};

export default SignCheckbox;
