import { useDispatch } from "react-redux";
import { showSidebar } from "../../../store/actions/sidebar";
import { Link } from "react-router-dom";

const TopbarButton = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    document.body.classList.add("aux-navigation-active");
    document.body.classList.add("no-scroll");
    dispatch(showSidebar());
  };

  return (
    <li className="aux-navigation hide">
      <Link className="navigation-show side-nav-show nav-icon" onClick={() => handleClick()} style={{ cursor: "pointer" }}>
        <span className="icon-menu"></span>
      </Link>
    </li>
  );
};

export default TopbarButton;
