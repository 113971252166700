import React, { useState, useEffect } from "react";
import { sitemap } from "../Layout/Sitemap";
import SidebarMenuItem from "./SidebarMenuItem";
import { useSelector, useDispatch } from "react-redux";
import { hidingSidebar } from "../../../store/actions/sidebar";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [openMenus, setOpenMenus] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sidebar = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(hidingSidebar());
  };

  const toggleMenu = (path) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [path]: !prevOpenMenus[path],
    }));
  };

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  }, []);

  const sidebarClassNames = {
    "side-navigation-wrapper": true,
    "enter-right": true,
    "slide-in-reset": true,
    "no-scrollbar": true,
    active: sidebar !== 0 && windowWidth < 961,
    "slide-in": sidebar === 1,
  };

  return (
    <aside className={classNames(sidebarClassNames)} data-no-scrollbar data-animation="slide-in">
      <div className="side-navigation-scroll-pane">
        <div className="side-navigation-inner">
          <div className="side-navigation-header">
            <div className="navigation-hide side-nav-hide" onClick={() => handleClick()}>
              <Link>
                <span className="icon-cancel medium"></span>
              </Link>
            </div>
          </div>
          <nav className="side-navigation nav-block">
            <ul>
              {sitemap
                .filter((item) => item.navigation && item.hide !== true)
                .map((firstDepthMenu) => (
                  <SidebarMenuItem
                    key={firstDepthMenu.path}
                    name={firstDepthMenu.name}
                    to={firstDepthMenu.path}
                    onClick={() => {
                      firstDepthMenu.children ? toggleMenu(firstDepthMenu.path) : handleClick();
                    }}
                  >
                    {firstDepthMenu.children && (
                      <ul
                        className="sub-menu"
                        style={{
                          height: openMenus[firstDepthMenu.path] ? "0" : "250px",
                          overflow: "hidden",
                          transition: "height 0.3s ease",
                        }}
                      >
                        {firstDepthMenu.children
                          .filter((item) => item.navigation)
                          .map((secondDepthMenu) => (
                            <SidebarMenuItem
                              key={secondDepthMenu.path}
                              name={secondDepthMenu.name}
                              to={firstDepthMenu.path + "/" + secondDepthMenu.path}
                              onClick={handleClick}
                            />
                          ))}
                      </ul>
                    )}
                  </SidebarMenuItem>
                ))}
            </ul>
          </nav>
          <div className="side-navigation-footer" />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
