import { Link, useResolvedPath } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { sitemap } from "../Layout/Sitemap";

const TopbarMenuItem = ({ children, name, to, ...props }) => {
  const breadcrumbs = useReactRouterBreadcrumbs(sitemap);
  const resolved = useResolvedPath(to);
  const match = breadcrumbs.map((breadcrumb) => breadcrumb.match.pathname).includes(resolved.pathname);

  return (
    <li className={match ? "current" : undefined}>
      <Link to={to} className={children ? "contains-sub-menu" : undefined}>
        {name}
      </Link>
      {children}
    </li>
  );
};

export default TopbarMenuItem;
