import React from "react";
import Category from "../Category";
import ResetPasswordContent from "../content/ResetPasswordContent";
import TitleContainer from "../TitleContainer";

const ResetPassword = () => {
  return (
    <>
      <TitleContainer title="마이페이지" description="내 정보를 관리할 수 있습니다." style={{ "bkg-grey-ultralight": true }} />
      <div className="mypage-category">
        <Category />
      </div>
      <div className="mypage-content">
        <ResetPasswordContent />
      </div>
    </>
  );
};

export default ResetPassword;
