import TitleContainer from "../../components/common/Layout/TitleContainer";
import PrivacyContents from "../../components/Privacy/PrivacyContents";

const Privacy = () => (
    <>
        <TitleContainer title="개인정보 처리방침" description="개인정보 보호를 위해 노력하겠습니다" />
        <PrivacyContents />
    </>
);

export default Privacy;
