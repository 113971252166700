import React, { useState } from "react";
import SuccessAdded from "./SuccessAddedModal";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../constants/colors";
import { authInstance } from "../../store/api/baseInstance";

const AddKeyModal = ({ toggleModal, getKeyData }) => {
  const [bindingIP, setBindingIP] = useState("");
  const [note, setNote] = useState("");
  const [isIPValid, setIsIPValid] = useState(true);
  const [bindingErrorMsg, setBindingErrorMsg] = useState("");
  const [rpcKey, setRpcKey] = useState(null);
  const [showNewModal, setShowNewModal] = useState(false);

  const handleIPChange = (e) => {
    const value = e.target.value;
    setBindingIP(value);
    validateIP(value);
  };

  const validateIP = (ip) => {
    const ipPattern =
      /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;
    setIsIPValid(ipPattern.test(ip));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (bindingIP.length === 0) {
      setBindingErrorMsg("바인딩 IP를 입력해주세요.");
      return;
    } else if (!isIPValid) {
      setBindingErrorMsg("바인딩 IP를 확인해주세요.");
      return;
    }

    try {
      const response = await authInstance.post("/node/rpc/keys", {
        bindingIp: bindingIP,
        memo: note,
      });
      if (response.status === 201) {
        const newRpcKey = response.data.rpcKey;
        setRpcKey(newRpcKey);
        setShowNewModal(true);
        getKeyData();
      }
    } catch (error) {
      console.error("RPC 키를 생성하는데 실패하였습니다: ", error);
      if (error.response && error.response.data && error.response.data.message) {
        setBindingErrorMsg(error.response.data.message);
      } else {
        setBindingErrorMsg("알 수 없는 에러가 발생하였습니다.");
      }
    }
  };

  const handleCloseNewModal = () => {
    setShowNewModal(false);
    toggleModal();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {showNewModal ? (
        <SuccessAdded rpcKey={rpcKey} bindingIP={bindingIP} note={note} closeModal={handleCloseNewModal} />
      ) : (
        <>
          <div
            id="tm-lightbox"
            className="tm-lightbox tml-newsletter-modal height-auto tml-modal-mode tml-padding-small tml-swap-exit-light"
            style={{
              transition: "-webkit-transform 350ms cubic-bezier(0.42, 0, 0.58, 1) 0s",
              opacity: "1",
              visibility: "visible",
              transform: "(0px, 0px, 0px, 0px)",
            }}
            onClick={toggleModal}
          >
            <div
              id="tml-content-wrapper"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <div
                id="tml-content"
                className="modal-dialog scale-in scrollable-content"
                style={{
                  transition: "-webkit-transform 350ms cubic-bezier(0.42, 0, 0.58, 10 0s",
                  opacity: "1",
                  visibility: "visible",
                  width: "100%",
                  maxWidth: "600px",
                }}
                onClick={stopPropagation}
              >
                <div id="subscribe-modal-simple" className="pt-70 pb-50">
                  <div className="row">
                    <div className="column width-10 offset-1 center">
                      <h3 className="mb-10">RPC 키 생성</h3>
                      <p className="mb-30">RPC를 사용하기 위한 키를 생성합니다.</p>
                      <div className="signup-form-container">
                        <form onSubmit={handleSubmit}>
                          <div className="row node-form">
                            <div className="row width-8">
                              <div className="field-wrapper">
                                <input
                                  type="text"
                                  name="binding IP"
                                  className="form-fname form-element rounded medium"
                                  placeholder="바인딩 IP*"
                                  tabIndex="1"
                                  value={bindingIP}
                                  onChange={handleIPChange}
                                />
                                {bindingErrorMsg && (
                                  <div style={{ color: "red", textAlign: "left", fontSize: "1.3rem", marginBottom: "2rem" }}>{bindingErrorMsg}</div>
                                )}
                              </div>
                            </div>
                            <div className="row width-8">
                              <div className="field-wrapper">
                                <input
                                  type="text"
                                  name="note"
                                  className="form-wesite form-element rounded medium"
                                  placeholder="메모"
                                  tabIndex="4"
                                  value={note}
                                  onChange={(e) => setNote(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="column width-12">
                              <input type="submit" value="생성" style={buttonStyle} className="button rounded add-button hover-717dfb" />
                            </div>
                          </div>
                        </form>
                        <div className="form-response"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <Link to="#" id="tml-exit" className="tml-nav cursor-pointer" onClick={toggleModal}></Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default AddKeyModal;
