import React from "react";
import OrderItem from "../../components/Ordered/OrderItem";
import { Link } from "react-router-dom";
import { buttonStyle } from "../../constants/colors";

const OrderCompleted = () => {
  const orderItems = [
    {
      title: "Walnut iPad Stand",
      description: "for 10.5, 12.9",
      price: "3,000",
      quantity: 1,
    },
    {
      title: "Mophie Powerstation XL",
      description: "for phones and tablets",
      price: "2,000",
      quantity: 1,
    },
    {
      title: "iPhone 6 smart Bat. Case",
      description: "color charcoal grey",
      price: "100",
      quantity: 1,
    },
  ];
  const totalPrice = orderItems
    .reduce((acc, item) => {
      const price = parseFloat(item.price.replace(/,/g, ""));
      return acc + price * item.quantity;
    }, 0)
    .toLocaleString("ko-KR");
  return (
    <>
      <div class="content clearfix">
        <div class="section-block intro-title-1 small">
          <div class="row">
            <div class="column width-10 offset-1">
              <div class="title-container">
                <div class="title-container-inner">
                  <div class="row flex">
                    <div class="column width-12 v-align-middle">
                      <div>
                        <h1 class="mb-0 font-400">주문완료</h1>
                        <p class="lead mb-0 mb-mobile-20">주문이 정상적으로 처리되었습니다.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-block pt-0">
          <div class="row">
            <div class="column width-10 offset-1">
              <div class="with-background">
                <div class="row">
                  <div class="column width-12">
                    <p class="lead">
                      Thank you for your payment of <strong>₩{totalPrice}</strong> on <strong>28 Aug 2017</strong> using <strong>PayPal</strong>.
                    </p>
                    <p>자세한 내용은 이메일로 발송되었습니다.</p>
                  </div>
                  <div class="column width-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="column width-12">
                    <h4 class="mb-30">주문번호</h4>
                    <div class="box small rounded bkg-green color-white shadow">
                      <span class="order-number title-medium opacity-07">M-24070917240001</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="column width-12">
                    <h4 class="mb-30">주문 개요</h4>
                    <div class="cart-overview">
                      <table class="table non-responsive rounded large mb-50">
                        <thead>
                          <tr>
                            <th class="product-details">상품명</th>
                            <th class="product-quantity center">수량</th>
                            <th class="product-subtotal right">가격</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderItems.map((item, index) => (
                            <OrderItem key={index} title={item.title} description={item.description} price={item.price} quantity={item.quantity} />
                          ))}
                          <tr class="cart-shipping-total right">
                            <td colspan="3">
                              Shipping (US): <span class="amount">$10.00</span>
                            </td>
                          </tr>
                          <tr class="cart-order-tax right">
                            <td colspan="3">
                              부가세: <span class="amount">463원</span>
                            </td>
                          </tr>
                          <tr class="cart-order-total right">
                            <td colspan="3">
                              합계: <span class="amount">{totalPrice}원</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="column width-12 right">
                    <Link to="#" style={buttonStyle} class="button rounded large text-small hover-717dfb">
                      돌아가기
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCompleted;
