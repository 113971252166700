import React from "react";
import Category from "../../components/Mypage/Category";
import InfoContent from "../../components/Mypage/content/InfoContent";
import TitleContainer from "../../components/Mypage/TitleContainer";
import PointContent from "../../components/Mypage/content/PointContent";

const Mypage = () => {
  return (
    <>
      <TitleContainer title="마이페이지" description="내 정보를 관리할 수 있습니다." style={{ "bkg-grey-ultralight": true }} />
      <div className="mypage-category">
        <Category />
      </div>
      <div className="mypage-content">
        <InfoContent />
        <PointContent />
      </div>
    </>
  );
};

export default Mypage;
