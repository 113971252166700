import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import sidebar, { sidebarSaga } from "./sidebar";
import auth, { authSaga } from "./auth";

export function* rootSaga() {
  yield all([sidebarSaga(), authSaga()]);
}

export default combineReducers({
  sidebar,
  auth,
});
